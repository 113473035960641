import {
  IFetchTabsByOutletRequest,
  IFetchTabsByOutletBodyPayload,
  IFetchTabsByOutletContract,
  IFetchTabsByOutletResponse,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

const sortKeyHandler = (
  sortBy: NonNullable<IFetchTabsByOutletRequest["sort"]>
): IFetchTabsByOutletBodyPayload["sort"] => {
  if (sortBy.field === "reference") {
    return sortBy.direction === "ASC" ? "id" : "-id";
  }

  if (sortBy.field === "closedAt") {
    return sortBy.direction === "ASC" ? "closed_at" : "-closed_at";
  }

  if (sortBy.field === "outstandingBalance") {
    return sortBy.direction === "ASC"
      ? "outstanding_balance"
      : "-outstanding_balance";
  }

  if (sortBy.field === "createdAt") {
    return sortBy.direction === "ASC" ? "id" : "-id";
  }

  if (sortBy.field === "spot") {
    return sortBy.direction === "ASC" ? "table_name" : "-table_name";
  }

  if (sortBy.field === "owner") {
    return sortBy.direction === "ASC" ? "owner_name" : "-owner_name";
  }

  if (sortBy.field === "total") {
    return sortBy.direction === "ASC" ? "total" : "-total";
  }

  if (sortBy.field === "state") {
    return sortBy.direction === "ASC" ? "state" : "-state";
  }

  return "-id";
};

export class FetchTabsByOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTabsByOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchTabsByOutlet(
  request: IFetchTabsByOutletRequest
): Promise<IFetchTabsByOutletResponse> {
  let response: AxiosResponse<IFetchTabsByOutletContract>;

  try {
    const params: IFetchTabsByOutletBodyPayload = {
      state: request.state,
      outlet_id: request.outletId,
      per_page: request.perPage ?? 15,
      page: request.page ?? 1,
      reference: request.reference?.length ? request.reference : undefined,
      ...(request.createdAt
        ? {
            "created_at[from]": request.createdAt.start.toJSON(),
            "created_at[to]": request.createdAt.end.toJSON(),
          }
        : {}),
      ...(request.closedAt
        ? {
            "closed_at[from]": request.closedAt.start.toJSON(),
            "closed_at[to]": request.closedAt.end.toJSON(),
          }
        : {}),
      sort: request.sort ? sortKeyHandler(request.sort) : undefined,
    };

    response = await http.get(`api/v3/businesses/${request.businessId}/tabs`, {
      params,
    });
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTabsByOutletError(e);
  }

  const data = response.data.data;
  const meta = response.data.meta;

  return {
    data: data?.length
      ? response.data.data.map((tab) => ({
          id: tab.id,
          reference: tab.reference,
          spot: {
            id: tab.table.id,
            name: tab.table.name,
          },
          state: tab.state,
          createdAt: new Date(tab.created_at),
          closedAt: tab.closed_at ? new Date(tab.closed_at) : undefined,
          owner: {
            name: tab.owner.name,
            phoneNumber: tab.owner.phone_number,
          },
          outstandingBalance: tab.outstanding_balance,
          total: tab.total,
        }))
      : [],
    meta: {
      currentPage: meta.current_page,
      lastPage: meta.last_page,
      perPage: meta.per_page,
      totalRecords: meta.total,
    },
  };
}
