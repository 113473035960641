var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('header')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H1}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.history.heading"))+" ")]),_c('OutletSwitcher',{class:_vm.css('outlet-switcher'),attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId}})],1),_c('OrgFilters',{class:_vm.css('filters'),attrs:{"config":_vm.filtersConfig},on:{"apply":_vm.onApplyFilters,"clear":_vm.onClearFilters}}),_c('OrgTable',{class:_vm.css('datatable'),attrs:{"loading":_vm.isLoading,"columns":_vm.columns,"total-records":_vm.pagination.perPage,"data":_vm.tabs,"on-sort":_vm.onTableSort,"row-click":function (row, event) { return _vm.onRowClick(row.id, event); },"no-records-msg":_vm.t('module.tabs.history.table_no_data'),"has-actions":""},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',{class:_vm.css('th-reference'),attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v("#"+_vm._s(cell))])]}},{key:"createdAt",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomDate',{attrs:{"datetime":cell,"format":"DD/MM/YY HH:mm"}})],1)]}},{key:"closedAt",fn:function(ref){
var cell = ref.cell;
return [(cell)?_c('AtomText',[_c('AtomDate',{attrs:{"datetime":cell,"format":"DD/MM/YY HH:mm"}})],1):_vm._e()]}},{key:"owner",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell.name))])]}},{key:"spot",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell.name))])]}},{key:"total",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"outstandingBalance",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"state",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('TabsTabStateLabel',{attrs:{"state":cell}})],1)]}}])}),(_vm.tabs.length)?_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"total":_vm.pagination.totalRecords,"on-navigate":_vm.handlePagination,"per-page":_vm.pagination.perPage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }