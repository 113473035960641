import {
  IAddOfflinePaymentRequest,
  IAddOfflinePaymentBodyPayload,
  IAddOfflinePaymentKnownErrorCode,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../../http";

export class AddOfflinePaymentError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AddOfflinePayment");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/6d91098b6fbca-add-an-offline-payment API Contract}
 */
export async function addOfflinePayment(
  request: IAddOfflinePaymentRequest
): Promise<void> {
  const params: IAddOfflinePaymentBodyPayload = {
    outlet_id: request.outletId,
    payment_method: request.paymentMethod,
    amount: request.amount,
    tip_amount: request.tipAmount,
  };

  try {
    await http.post(
      `api/v4/businesses/${request.businessId}/tabs/${request.tabId}/payments`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const errorCodeFromApi: Array<IAddOfflinePaymentKnownErrorCode> = [
      "no_outstanding_balance",
      "insufficient_outstanding_balance",
    ];

    const customError = e?.response?.data?.error?.code;
    if (errorCodeFromApi.includes(customError) || e?.response?.status === 423) {
      e.errorCode = customError ?? "tab_is_locked";
    }

    throw new AddOfflinePaymentError(e);
  }
}
