import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";

interface IFetchMenuItemRequest {
  itemId: string;
  outletId: string;
}

interface IPrice {
  value: number;
  currency: string;
}

export interface IFetchMenuItemResponse {
  id: string;
  name: string;
  description?: string;
  price: IPrice;
  photo?: string;
  modifierLists: Array<{
    id: string;
    name: string;
    sort: number;
    selectionType: string;
    minimumSelection: number;
    maximumSelection: number;
    modifiers: Array<{
      id: string;
      name: string;
      description?: string;
      photo?: string;
      price: IPrice;
    }>;
  }>;
}

interface IFetchMenuItemContract {
  id: string;
  name: string;
  description?: string;
  price: IPrice;
  photo?: string;
  modifier_lists?: Array<{
    id: string;
    name: string;
    sort: number;
    select_type: string;
    min_selection: number;
    max_selection: number;
    modifiers: Array<{
      id: string;
      name: string;
      description?: string;
      photo?: string;
      price: IPrice;
    }>;
  }>;
}

export class FetchMenuItemError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchMenuItemError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchMenuItem(
  request: IFetchMenuItemRequest
): Promise<IFetchMenuItemResponse> {
  let response: AxiosResponse<IFetchMenuItemContract>;

  try {
    response = await http.get(
      `/storefront/item-with-modifiers/${request.itemId}`,
      {
        params: {
          outlet_id: request.outletId,
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchMenuItemError(error);
  }

  return {
    id: response.data.id,
    name: response.data.name,
    description: response.data.description,
    price: {
      value: response.data.price.value,
      currency: response.data.price.currency,
    },
    photo: response.data.photo,
    modifierLists: response.data.modifier_lists?.length
      ? response.data.modifier_lists.map((modifierList) => ({
          id: modifierList.id,
          name: modifierList.name,
          sort: modifierList.sort,
          selectionType: modifierList.select_type,
          minimumSelection: modifierList.min_selection,
          maximumSelection: modifierList.max_selection,
          modifiers: modifierList.modifiers.length
            ? modifierList.modifiers.map((item) => ({
                id: item.id,
                name: item.name,
                description: item.description,
                photo: item.photo,
                price: {
                  value: item.price.value,
                  currency: item.price.currency,
                },
              }))
            : [],
        }))
      : [],
  };
}
