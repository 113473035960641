export type IAddOfflinePaymentRequest = {
  businessId: string;
  outletId: string;
  tabId: string;
  paymentMethod: AddOfflinePaymentMethodEnum;
  tipAmount: number;
  amount: number;
};

export type IAddOfflinePaymentBodyPayload = {
  outlet_id: string;
  payment_method: AddOfflinePaymentMethodEnum;
  amount: number;
  tip_amount: number;
};

export enum AddOfflinePaymentMethodEnum {
  CASH = "CASH",
  CARD_OFFLINE = "CARD_OFFLINE",
  CARD_ONLINE = "CARD_ONLINE",
}
