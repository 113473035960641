


























import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("mol-card-header");

export default defineComponent({
  name: "MolCardHeader",
  components: {
    AtomText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    requiredLabel: {
      type: String,
      default: "required",
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
