







































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  MolFormControl,
  MolInputPassword,
  AtomAutoSizeTextarea,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { formValidation } from "@chatfood/core-utils";
import { IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { markAvailableSpot } from "@/v2/repo/spots-occupancy/mark-available-spot";
import { closeTab } from "@/v2/repo/tabs/close-tab";
import { useRouter } from "@/router";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("tabs-close-tab");

export default defineComponent({
  name: "TabsCloseTab",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    MolFormControl,
    MolInputPassword,
    AtomAutoSizeTextarea,
  },
  props: {
    onClickCancel: {
      type: Function,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    spotId: {
      type: String,
      required: true,
    },
    outstandingBalance: {
      type: Object as PropType<IFetchTabResponse["outstandingBalance"]>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const isCloseDisabled = ref(false);
    const isCloseAndClearDisabled = ref(false);

    const managerPinError = ref<string | undefined>(undefined);

    function onTabClosed() {
      router.replace({
        name: "tabs.live",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });

      new Toast().create({
        type: "success",
        text: t("module.tabs.close_tab.success_close_tab"),
      });
    }
    const errors = ref({});

    const formValues = ref<Record<string, string>>({
      reason: "",
      managerPin: "",
    });

    const formConfig = {
      managerPin: {
        required: {
          message: t("module.tabs.close_tab.pin_validation"),
        },
      },
      reason: {
        required: {
          message: t("module.tabs.close_tab.valid_reason_error"),
        },
      },
    };

    const isFormInvalid = (): boolean => {
      errors.value = formValidation(formConfig, formValues.value);

      return Boolean(Object.keys(errors.value).length);
    };

    async function onCloseTab() {
      managerPinError.value = "";

      if (props.outstandingBalance.value > 0 && isFormInvalid()) return;

      isCloseAndClearDisabled.value = true;

      try {
        await closeTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          pin: formValues.value.managerPin,
          reason: formValues.value.reason,
        });

        onTabClosed();
      } catch (e: any) {
        if (hasCustomError(e)) {
          return;
        }

        report(e);
        generalErrorToast();
      } finally {
        isCloseAndClearDisabled.value = false;
      }
    }

    async function closeTabAndClearSpot() {
      managerPinError.value = "";

      if (props.outstandingBalance.value > 0 && isFormInvalid()) return;

      isCloseDisabled.value = true;

      try {
        await closeTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          pin: formValues.value.managerPin,
          reason: formValues.value.reason,
        });

        await markAvailableSpot({
          outletId: props.outletId,
          spotId: props.spotId,
        });

        onTabClosed();
      } catch (e: any) {
        if (hasCustomError(e)) {
          return;
        }

        report(e);
        generalErrorToast();
      } finally {
        isCloseDisabled.value = false;
      }
    }

    function hasCustomError(e: any) {
      const mapErrors: Record<string, string> = {
        action_required_manager_validation: t(
          "module.tabs.close_tab.error_void_item_require_pin"
        ),
        invalid_pin: t("module.tabs.close_tab.error_void_item_pin"),
      };

      const hasCustomError = mapErrors[e?.errors?.errorCode];

      if (hasCustomError) {
        managerPinError.value = hasCustomError;
        return true;
      }

      return false;
    }

    return {
      t,
      css,
      onCloseTab,
      isCloseDisabled,
      closeTabAndClearSpot,
      isCloseAndClearDisabled,
      managerPinError,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      formValues,
      hasCustomError,
      errors,
    };
  },
});
