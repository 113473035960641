import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";

interface IFetchMenusForOutletRequest {
  businessId: string;
  outletId: string;
}

export type IFetchMenusForOutletResponse = {
  id: string;
  name: string;
  image?: string;
  icon?: string;
  slug: string;
  description?: string;
};

export class FetchMenusForOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchMenusForOutletError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchMenusForOutlet(
  request: IFetchMenusForOutletRequest
): Promise<Array<IFetchMenusForOutletResponse>> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `storefront/businesses/${request.businessId}/outlets/${request.outletId}/menus?ordering_service=dine-in`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchMenusForOutletError(error);
  }

  return response.data.data.length
    ? response.data.data.map((menu: IFetchMenusForOutletResponse) => ({
        id: menu.id,
        name: menu.name,
        image: menu.image,
        icon: menu.icon,
        slug: menu.slug,
        description: menu.description,
      }))
    : [];
}
