import { IAddItemsForTabRequest, IAddItemsForTabBodyParams } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class AddItemsForTabError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AddItemsForTab");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function addItemsForTab(
  request: IAddItemsForTabRequest
): Promise<boolean> {
  let response: AxiosResponse;

  try {
    const params: IAddItemsForTabBodyParams = {
      outlet_id: request.outletId,
      note: request.notes?.length > 0 ? request.notes : undefined,
      items: request.items.length
        ? request.items.map((item) => ({
            item_id: item.itemId,
            quantity: item.quantity,
            notes: item?.note ?? undefined,
            modifier_items: item.modifierItems.length
              ? item.modifierItems.map((modifier) => ({
                  item_id: modifier.itemId,
                  quantity: modifier.quantity,
                  modifier_list_id: modifier.modifierListId,
                }))
              : [],
          }))
        : [],
    };

    response = await http.post(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/items`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new AddItemsForTabError(e);
  }

  return Boolean(response);
}
