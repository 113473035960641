




























































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed, ref } from "@vue/composition-api";
import {
  AtomMoney,
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  MolModalConfirm,
  AtomDate,
} from "@/v2/new-design-system";
import {
  IFetchTabResponse,
  IFetchTabResponsePayment,
} from "@/v2/repo/tabs/fetch-tab";
import { removeDiscount } from "@/v2/repo/tabs/remove-discount";
import { TabsVoucher } from "@/v2/module/tabs/voucher";
import { AddOfflinePaymentMethodEnum } from "@/v2/repo/tabs/add-offline-payment";
import { t } from "@/i18n";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("tabs-summary");

export default defineComponent({
  name: "TabsSummary",
  components: {
    AtomMoney,
    AtomIcon,
    AtomText,
    TabsVoucher,
    MolModalConfirm,
    AtomDate,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    owner: {
      type: Object as PropType<IFetchTabResponse["owner"]>,
      required: true,
    },
    joiners: {
      type: Array as PropType<IFetchTabResponse["joiners"]>,
      default: () => [],
    },
    charges: {
      type: Array as PropType<IFetchTabResponse["charges"]>,
      default: () => [],
    },
    discounts: {
      type: Array as PropType<IFetchTabResponse["discounts"]>,
      default: () => [],
    },
    payments: {
      type: Array as PropType<IFetchTabResponse["payments"]>,
      default: () => [],
    },
    outstandingBalance: {
      type: Object as PropType<IFetchTabResponse["outstandingBalance"]>,
      required: true,
    },
    subtotal: {
      type: Object as PropType<IFetchTabResponse["subtotal"]>,
      required: true,
    },
    total: {
      type: Object as PropType<IFetchTabResponse["total"]>,
      required: true,
    },
    isTabOpen: {
      type: Boolean,
      default: false,
    },
    isRecalculatingTab: {
      type: Boolean,
      required: true,
    },
    closureReason: {
      type: String,
      default: undefined,
    },
  },
  emits: {
    recalculate: () => true,
  },
  setup(props, { emit }) {
    const visiblePaymentStates: Array<
      IFetchTabResponse["payments"][0]["state"]
    > = ["PROCESSING", "CONFIRMED"];
    const visiblePayments = computed(() =>
      props.payments?.filter((payment) =>
        visiblePaymentStates.includes(payment.state)
      )
    );

    const showRemoveDiscountModal = ref(false);
    const isRemovingDiscount = ref(false);
    const discountToBeRemoved = ref("");

    function startRemoveDiscount(discountId: string) {
      discountToBeRemoved.value = discountId;
      showRemoveDiscountModal.value = true;
    }

    function closeRemoveDiscountModal() {
      discountToBeRemoved.value = "";
      showRemoveDiscountModal.value = false;
    }

    async function onRemoveDiscount() {
      isRemovingDiscount.value = true;

      try {
        await removeDiscount({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          discountId: discountToBeRemoved.value,
        });
      } catch (e: any) {
        report(e);
        generalErrorToast();
      } finally {
        closeRemoveDiscountModal();
        isRemovingDiscount.value = false;
      }
    }

    function findPayer(payment: IFetchTabResponsePayment) {
      if (payment.type === "OFFLINE") {
        return payment.method ?? t("module.tabs.summary.cash");
      }

      const mapOfflinePayment: Record<string, string> = {
        [AddOfflinePaymentMethodEnum.CARD_OFFLINE]: t(
          "module.tabs.summary.card"
        ),
        [AddOfflinePaymentMethodEnum.CASH]: t("module.tabs.summary.cash"),
      };

      const payers = [props.owner];

      if (props.joiners?.length) {
        payers.push(...props.joiners);
      }

      const hasPayer = payers.find(
        (obj) => obj.phoneNumber === payment.addedBy.phoneNumber
      );

      return (
        mapOfflinePayment[payment.type] ||
        hasPayer?.name ||
        payment.addedBy?.phoneNumber ||
        payment.addedBy?.email
      );
    }

    function onRecalculate() {
      emit("recalculate");
    }

    return {
      t,
      css,
      findPayer,
      onRecalculate,
      visiblePayments,
      showRemoveDiscountModal,
      isRemovingDiscount,
      startRemoveDiscount,
      closeRemoveDiscountModal,
      onRemoveDiscount,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
