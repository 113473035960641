import { IRecalculateTabRequest } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class RecalculateTabError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: RecalculateTab");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function recalculateTab(
  request: IRecalculateTabRequest
): Promise<boolean> {
  try {
    await http.post(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/recalculate`,
      {
        outlet_id: request.outletId,
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new RecalculateTabError(e);
  }

  return true;
}
