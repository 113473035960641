import { IMoveTabToSpotRequest, IMoveTabToSpotBodyParams } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class MoveTabToSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: MoveTabToSpot");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function moveTabToSpot(
  request: IMoveTabToSpotRequest
): Promise<boolean> {
  let response: AxiosResponse;

  const params: IMoveTabToSpotBodyParams = {
    outlet_id: request.outletId,
    table_id: request.spotId,
  };

  try {
    response = await http.put(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/move-to-table`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new MoveTabToSpotError(e);
  }

  return Boolean(response);
}
