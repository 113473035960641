import {
  IMoveItemToTabRequest,
  IMoveItemToTabBodyParams,
  IMoveItemToTabKnownErrorCode,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class MoveItemToTabError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: MoveItemToTab");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function moveItemToTab(
  request: IMoveItemToTabRequest
): Promise<void> {
  try {
    const params: IMoveItemToTabBodyParams = {
      outlet_id: request.outletId,
      quantity: request.quantity,
      destination_tab_id: request.targetTabId,
    };

    await http.post(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/line-items/${request.lineItemId}/move`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const errorCodeFromApi: Array<IMoveItemToTabKnownErrorCode> = [
      "cannot_be_moved_as_tab_becomes_over_paid",
      "more_item_than_existing_quantity_cannot_be_moved",
      "non_accountable_line_item_cannot_be_moved",
      "destination_tab_not_found",
    ];

    const customError = e?.response?.data?.error?.code;
    if (errorCodeFromApi.includes(customError) || e?.response?.status === 423) {
      e.errorCode = customError ?? "tab_is_locked";
    }

    throw new MoveItemToTabError(e);
  }
}
