import { IRemoveDiscountRequest, IRemoveDiscountBodyPayload } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class RemoveDiscountError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: RemoveDiscount");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function removeDiscount(
  request: IRemoveDiscountRequest
): Promise<boolean> {
  const params: IRemoveDiscountBodyPayload = {
    outlet_id: request.outletId,
  };

  try {
    await http.put(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/discounts/${request.discountId}/remove`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new RemoveDiscountError(e);
  }

  return true;
}
