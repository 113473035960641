import {
  IAddVoucherForTabRequest,
  IAddVoucherBodyPayload,
} from "@/v2/repo/tabs/add-voucher";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class AddVoucherForTabError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AddVoucherForTab");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function addVoucherForTab(
  request: IAddVoucherForTabRequest
): Promise<void> {
  try {
    const params: IAddVoucherBodyPayload = {
      outlet_id: request.outletId,
      code: request.code,
    };

    await http.post(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/discounts`,
      {
        ...params,
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new AddVoucherForTabError(e);
  }
}
