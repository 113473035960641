var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('header')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H1}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.live.heading"))+" ")]),_c('OutletSwitcher',{class:_vm.css('select-outlets'),attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId}})],1),(_vm.spots.length)?_c('div',{class:_vm.css('filter')},[_c('MolSearchBox',{attrs:{"autofocus":"","on-input":function (value) { return _vm.setSearch(value.trim()); },"placeholder":_vm.t('module.tabs.live.search_placeholder')}}),_c('AtomCheckbox',{class:_vm.css('toggle'),attrs:{"type":"success","checked":_vm.isSettledTabsVisible,"on-change":function (state) { return (_vm.isSettledTabsVisible = state); }}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.live.filter_settled_tabs"))+" ")]),_c('AtomCheckbox',{class:_vm.css('toggle'),attrs:{"type":"danger","checked":_vm.isOpenTabsVisible,"on-change":function (state) { return (_vm.isOpenTabsVisible = state); }}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.live.filter_open_tabs"))+" ")]),_c('AtomCheckbox',{class:_vm.css('toggle'),attrs:{"type":"neutral","checked":_vm.isEmptyTablesVisible,"on-change":function (state) { return (_vm.isEmptyTablesVisible = state); }}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.live.filter_empty_spots"))+" ")])],1):_vm._e(),_c('div',{class:[
      _vm.css('content'),
      ( _obj = {}, _obj[_vm.css('content', 'empty')] = !_vm.filteredSpots.length, _obj ) ]},[(_vm.isLoading)?_c('AtomLoading',{attrs:{"floating":""}}):(_vm.groupsCount === 1)?_vm._l((_vm.filteredSpots),function(group,index){
    var _obj;
return _c('div',{key:(group + "-" + index),class:[_vm.css('group'), _vm.css('group', 'single')]},[_c('div',{class:[
            _vm.css('group-list'),
            ( _obj = {}, _obj[_vm.css('group-list', 'one-line')] = group.spots.length < 4, _obj ) ]},_vm._l((group.spots),function(spot){return _c('TabsSpot',{key:spot.id,attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId,"spot-id":spot.id,"tab-id":spot.tabId,"spot-name":spot.name,"owner-name":spot.ownerName,"outstanding-balance":spot.outstandingBalance,"total":spot.total}})}),1)])}):(_vm.groupsCount > 1)?_vm._l((_vm.filteredSpots),function(group,index){
          var _obj;
return _c('OrgCollapseGroup',{key:(group + "-" + index),class:_vm.css('group'),attrs:{"name":group.name || _vm.t('module.tabs.live.others')}},[_c('div',{class:[
            _vm.css('group-list'),
            ( _obj = {}, _obj[_vm.css('group-list', 'one-line')] = group.spots.length < 4, _obj ) ]},_vm._l((group.spots),function(spot){return _c('TabsSpot',{key:spot.id,attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId,"spot-id":spot.id,"tab-id":spot.tabId,"spot-name":spot.name,"owner-name":spot.ownerName,"outstanding-balance":spot.outstandingBalance,"total":spot.total}})}),1)])}):_c('div',[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SUBTITLE}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.live.empty_title"))+" ")]),_c('AtomText',{attrs:{"color":_vm.AtomTextColorEnum.RAINY}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.live.empty_message_no_spots_filter"))+" ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }