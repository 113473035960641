import {
  IStartTabRequest,
  IStartTabBodyParams,
  IStartTabContract,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class StartTabError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: StartTab");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function startTab(request: IStartTabRequest): Promise<string> {
  let response: AxiosResponse<IStartTabContract>;

  try {
    const params: IStartTabBodyParams = {
      outlet_id: request.outletId,
      table_id: request.spotId,
      currency: request.currency,
      pax: request.cover,
      owner: {
        name: request.guestName,
        phone_number: request.guestPhoneNumber,
      },
    };

    response = await http.post(
      `api/v3/businesses/${request.businessId}/tabs/start`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new StartTabError(e);
  }

  return response.data.tab_id;
}
