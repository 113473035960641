var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('AtomText',{class:_vm.css('title'),attrs:{"type":_vm.AtomTextTypeEnum.H3}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.ordered_items.heading"))+" ")]),(_vm.isTabOpen)?_c('AtomButtonLink',{class:_vm.css('cta'),attrs:{"data-test":"add-items-button","type":_vm.AtomButtonLinkTypeEnum.LINK,"size":_vm.AtomButtonLinkSizeEnum.LARGE,"route":{
      name: 'tabs.add-items',
      params: { businessId: _vm.businessId, outletId: _vm.outletId, tabId: _vm.tabId },
    }}},[_c('AtomIcon',{attrs:{"size":21,"name":"add"}}),_vm._v(" "+_vm._s(_vm.t("module.tabs.ordered_items.add_items"))+" ")],1):_vm._e(),(_vm.itemsAvailables.length)?_c('ul',{class:_vm.css('list'),attrs:{"data-test":"list-items"}},_vm._l((_vm.itemsAvailables),function(item){
    var _obj;
return _c('li',{key:item.id,class:[
        _vm.css('list-item'),
        ( _obj = {}, _obj[_vm.css('list-item', 'voided')] = _vm.isVoidedState(item.state), _obj ) ],attrs:{"data-test":"item-line"}},[_c('div',{class:_vm.css('list-details')},[_c('div',{class:_vm.css('line-name')},[_c('AtomText',{class:_vm.css('item-name'),attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[(item.quantity > 1)?_c('span',{attrs:{"data-test":"item-quantity"}},[_vm._v(" "+_vm._s(item.quantity)+"x ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]),_c('AtomText',{class:_vm.css('item-price')},[_c('AtomMoney',{attrs:{"amount":item.totalPrice.value,"currency":item.totalPrice.currency}})],1)],1),_vm._l((item.modifierLists),function(modifierList,index){return _c('div',{key:((modifierList.name) + "-" + index),attrs:{"data-test":"item-modifier-list"}},_vm._l((modifierList.modifierItems),function(modifier){return _c('AtomText',{key:modifier.itemId,class:_vm.css('line-name'),attrs:{"tag":_vm.AtomTextTagEnum.DIV,"data-test":"item-modifier-item","color":_vm.AtomTextColorEnum.RAINY}},[_c('span',[(modifier.quantity > 1)?_c('span',{attrs:{"data-test":"item-modifier-quantity"}},[_vm._v(" "+_vm._s(modifier.quantity)+"x ")]):_vm._e(),_vm._v(" "+_vm._s(modifier.name)+" ")])])}),1)}),(item.note)?_c('AtomText',{class:_vm.css('block'),attrs:{"data-test":"item-note","color":_vm.AtomTextColorEnum.RAINY}},[_c('strong',[_vm._v(" "+_vm._s(_vm.t("module.tabs.ordered_items.note"))+": ")]),_vm._v(" "+_vm._s(item.note)+" ")]):_vm._e(),_c('TabsOrderedBy',{class:_vm.css('ordered-by'),attrs:{"label":_vm.getOrderedByLabel(item.id, item.addedBy.type),"tooltip-label":_vm.getOrderedByTooltipLabel(
              item.addedBy.type,
              item.addedBy.name,
              item.addedBy.phoneNumber
            ),"date-time":item.addedAt}})],2),(_vm.isTabOpen && !_vm.isVoidedState(item.state))?_c('div',{class:_vm.css('list-actions'),attrs:{"data-test":"actions-item-button"}},[_c('a',{class:[_vm.css('list-actions-btn'), _vm.css('list-actions-btn', 'move')],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onMoveItem(item)}}},[_c('AtomIcon',{attrs:{"name":"arrow-long-right","size":20}})],1),_c('a',{class:[_vm.css('list-actions-btn'), _vm.css('list-actions-btn', 'void')],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onVoidItem(item.id, item.quantity, item.unitPrice)}}},[_c('AtomIcon',{attrs:{"name":"delete"}})],1)]):_vm._e()])}),0):_c('AtomText',{class:_vm.css('no-items'),attrs:{"data-test":"empty-items"}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.ordered_items.no_items_yet"))+" ")]),(_vm.itemToBeVoided)?_c('VoidItems',{attrs:{"business-id":_vm.businessId,"item-price":_vm.itemToBeVoided.unitPrice,"item-quantity":_vm.itemToBeVoided.quantity,"outlet-id":_vm.outletId,"tab-id":_vm.tabId,"line-item-id":_vm.itemToBeVoided.itemId,"outstanding-balance":_vm.outstandingBalance},on:{"close":function($event){_vm.itemToBeVoided = undefined}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }