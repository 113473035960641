












































import { bemBuilder } from "@chatfood/core-utils";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  unref,
} from "@vue/composition-api";
import { AtomCheckbox } from "./../../atom/checkbox";
import { AtomRadio } from "./../../atom/radio";
import { MolCardHeader } from "./../../mol/card-header";
import { OrgSpinButton } from "./../spin-button";
import { AtomText, AtomTextTypeEnum, AtomMoney } from "@/v2/new-design-system";
import type {
  IOrgModifierGroupItem,
  IOrgModifierGroupState,
  IOrgModifierGroupComponentMap,
} from "./types";
import type { IOrgModifierItem } from "./types";

const css = bemBuilder("org-modifier-group");

export default defineComponent({
  name: "OrgModifierGroup",
  components: {
    AtomText,
    AtomMoney,
    AtomRadio,
    AtomCheckbox,
    MolCardHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    maxSelection: {
      type: Number,
      default: 0,
    },
    minSelection: {
      type: Number,
      default: 0,
    },
    validateOnMount: {
      type: Boolean,
      default: false,
    },
    modifiers: {
      type: Array as PropType<Array<IOrgModifierItem>>,
      default: () => [],
    },
    type: {
      type: String as PropType<"radio" | "multiple" | "checkbox">,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ["change", "validate"],
  setup(props, { emit }) {
    const isRequired = computed(() => Boolean(props.minSelection));

    onMounted(() => {
      if (props.validateOnMount) {
        validate();
      }
    });

    const modifierItems = ref<Array<IOrgModifierGroupItem>>(
      props.modifiers.map((element) => ({
        ...element,
        disabled: false,
        quantity: 0,
      }))
    );

    const modifierState = ref<IOrgModifierGroupState>({
      totalQuantity: 0,
      totalPrice: 0,
      selected: [],
      valid: !isRequired.value,
    });

    const componentMap = computed<IOrgModifierGroupComponentMap>(() => ({
      radio: {
        component: AtomRadio,
        className: "radio",
        events: {
          change: handleRadio,
        },
        defaultProps: {
          rightContent: true,
          name: props.title,
          checked: false,
        },
      },
      checkbox: {
        component: AtomCheckbox,
        className: "checkbox",
        events: {
          change: handleCheckbox,
        },
        defaultProps: {
          rightContent: true,
          checked: false,
        },
      },
      multiple: {
        component: OrgSpinButton,
        className: "spin-button",
        events: {
          increase: handleIncrease,
          decrease: handleDecrease,
        },
        defaultProps: {
          type: "square",
          count: 0,
        },
      },
    }));

    const componentSettings = computed(() => componentMap.value[props.type]);

    function handleRadio(id: string) {
      modifierItems.value.map(
        (item) => (item.quantity = item.id === id ? 1 : 0)
      );
      updateGroupState();
    }

    function handleCheckbox(state: boolean, id: string) {
      const item = findGroupItemById(id);
      if (item && !item.disabled) {
        item.quantity = item.quantity === 1 ? 0 : 1;
        updateGroupState();
        disableModifierItems();
      }
    }

    function handleIncrease(count: number, id: string) {
      const item = findGroupItemById(id);
      if (item && !isMaxSelected() && !item.disabled) {
        item.quantity++;
        updateGroupState();
        disableModifierItems();
      }
    }

    function handleDecrease(count: number, id: string) {
      const item = findGroupItemById(id);
      if (item && !item.disabled) {
        item.quantity--;
        updateGroupState();
        disableModifierItems();
      }
    }

    const findGroupItemById = (id: string) => {
      return modifierItems.value.find((item) => item.id === id);
    };

    function isMaxSelected() {
      return props.maxSelection <= modifierState.value.totalQuantity;
    }

    function isMinSelected() {
      return !(props.minSelection > modifierState.value.totalQuantity);
    }

    function disableModifierItems() {
      modifierItems.value.map((item) => {
        item.disabled = isMaxSelected() && item.quantity < 1;
        return item;
      });
    }

    function validate() {
      emit("validate", {
        valid: modifierState.value.valid,
        value: props.value,
      });
    }

    function updateGroupState() {
      const selectedItems = modifierItems.value
        .filter((item) => item.quantity > 0)
        .map(({ id, quantity, name, price }) => ({
          id,
          quantity,
          name,
          price,
          categoryId: props.value,
        }));

      const totalQuantity = selectedItems.reduce(
        (total: number, item): number => total + item.quantity,
        0
      );

      const totalPrice = selectedItems.reduce(
        (total: number, item): number =>
          total + item.price.value * item.quantity,
        0
      );

      modifierState.value.selected = unref(selectedItems);
      modifierState.value.totalQuantity = totalQuantity;
      modifierState.value.totalPrice = totalPrice;
      modifierState.value.valid = isMinSelected();

      emit("change", { ...modifierState.value, value: props.value });
      validate();
    }

    return {
      css,
      isRequired,
      modifierItems,
      modifierState,
      componentSettings,
      AtomTextTypeEnum,
    };
  },
});
