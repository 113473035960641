


























import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, ref, computed } from "@vue/composition-api";

const css = bemBuilder("atom-checkbox");

export default defineComponent({
  name: "AtomCheckbox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    partial: {
      type: Boolean,
      default: false,
    },
    rightContent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  setup(props, { slots, emit }) {
    const isChecked = ref(props.checked);
    const hasContent = computed(() => Boolean(slots.default));

    function handleChange() {
      if (props.disabled) return;

      emit("change", isChecked.value, props.value);
    }

    return {
      css,
      isChecked,
      hasContent,
      handleChange,
    };
  },
});
