




























import { t } from "@/i18n";
import { Toast } from "@/design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { addVoucherForTab } from "@/v2/repo/tabs/add-voucher";
import { defineComponent, ref } from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomInput,
  AtomButton,
} from "@/v2/new-design-system";

const css = bemBuilder("tabs-voucher");

export default defineComponent({
  name: "TabsVoucher",
  components: {
    AtomIcon,
    AtomText,
    AtomInput,
    AtomButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const showInputVoucher = ref(false);
    const voucherCode = ref("");

    async function onGenerateVoucher() {
      isLoading.value = true;

      try {
        const request = {
          businessId: props.businessId,
          tabId: props.tabId,
          code: voucherCode.value,
          outletId: props.outletId,
        };

        await addVoucherForTab(request);

        new Toast().create({
          type: "success",
          text: t("module.tabs.voucher.toast_success.text"),
        });

        showInputVoucher.value = false;
      } catch {
        new Toast().create({
          type: "error",
          text: t("module.tabs.voucher.toast_failure.text"),
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      t,
      css,
      isLoading,
      voucherCode,
      onGenerateVoucher,
      showInputVoucher,
    };
  },
});
