










































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomModal,
  AtomMoney,
  AtomLoading,
  MolSearchBox,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { OrgSpinButton } from "../components/org/spin-button";
import { TabsOrderedBy } from "../ordered-by";
import {
  fetchTabsByOutlet,
  IFetchTabsByOutletResponse,
} from "@/v2/repo/tabs/fetch-tabs-by-outlet";
import {
  moveItemToTab,
  IMoveItemToTabKnownErrorCode,
} from "@/v2/repo/tabs/move-item-to-tab";
import { IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { removeSpecialCharacters } from "@chatfood/core-utils";
import { phoneBeautifier } from "@chatfood/core-utils";
import { debounce } from "@/v2/util/debounce";
import { report } from "@chatfood/bug-reporter";
import { t, tc } from "@/i18n";

const css = bemBuilder("tabs-move-item");

export default defineComponent({
  name: "TabsMoveItem",
  components: {
    AtomText,
    AtomMoney,
    AtomModal,
    AtomButton,
    AtomLoading,
    MolSearchBox,
    OrgSpinButton,
    TabsOrderedBy,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    item: {
      type: Object as PropType<IFetchTabResponse["lineItems"][0]>,
      required: true,
    },
    itemsOwner: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const isLoading = ref(true);
    const search = ref("");
    const isMovingItemTabId = ref("");

    const itemQuantityUpdated = ref(props.item.quantity);

    onMounted(() => getOpenTabs());

    const openTabs = ref<IFetchTabsByOutletResponse["data"]>([]);
    async function getOpenTabs() {
      isLoading.value = true;

      try {
        const response = await fetchTabsByOutlet({
          businessId: props.businessId,
          outletId: props.outletId,
          state: ["OPEN"],
          perPage: 999,
        });

        openTabs.value = response.data
          .filter((tab) => tab.id !== props.tabId)
          .sort((a, b) =>
            a.spot.name.localeCompare(b.spot.name, "en", {
              numeric: true,
            })
          );
      } catch (error) {
        report(error);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function sanitizedString(value: string | undefined) {
      const lowerString = value ? value.toLowerCase().trim() : "";
      return removeSpecialCharacters(lowerString);
    }

    const filteredTabs = computed(() => {
      if (!search.value) return openTabs.value;

      const query = sanitizedString(search.value);

      return openTabs.value.filter(
        (tab) =>
          sanitizedString(tab.spot.name).includes(query) ||
          sanitizedString(tab.reference).includes(query)
      );
    });

    async function onMoveItemToTab(tabId: string, shouldRetry = false) {
      if (isMovingItemTabId.value) return;

      isMovingItemTabId.value = tabId;

      try {
        await moveItemToTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          lineItemId: props.item.id,
          quantity: itemQuantityUpdated.value,
          targetTabId: tabId,
        });

        onClose();
        new Toast().create({
          type: "success",
          text: t("module.tabs.move_item.success_message"),
        });
      } catch (e: any) {
        const errorCode = e?.errors?.errorCode;

        if (errorCode) {
          handleError(errorCode, tabId, shouldRetry);
          return;
        }

        report(e);
        generalErrorToast();
      } finally {
        isMovingItemTabId.value = "";
      }
    }

    function handleError(
      errorCode: IMoveItemToTabKnownErrorCode,
      tabId: string,
      isRetrying: boolean
    ) {
      const mapErrors: Record<IMoveItemToTabKnownErrorCode, string> = {
        cannot_be_moved_as_tab_becomes_over_paid: t(
          "module.tabs.move_item.error_over_paid"
        ),
        more_item_than_existing_quantity_cannot_be_moved: t(
          "module.tabs.move_item.error_more_item"
        ),
        non_accountable_line_item_cannot_be_moved: t(
          "module.tabs.move_item.error_non_accountable"
        ),
        destination_tab_not_found: t(
          "module.tabs.move_item.error_tab_not_found"
        ),
        tab_is_locked: t("module.tabs.move_item.warning_retrying"),
      };

      const knownErrorMessage = mapErrors[errorCode];

      if (errorCode === "tab_is_locked") {
        if (isRetrying) {
          generalErrorToast();
        } else {
          new Toast().create({
            type: "warning",
            text: knownErrorMessage,
          });

          setTimeout(() => onMoveItemToTab(tabId, true), 5000);
        }
      } else {
        generalErrorToast(knownErrorMessage);
      }
    }

    function onClose() {
      emit("close");
    }

    const handleSearch = debounce((query: string) => {
      search.value = query;
    }, 250);

    function updateQuantity(count: number) {
      if (count > props.item.quantity || count === 0) return;

      itemQuantityUpdated.value = count;
    }

    function getOrderedByLabel(itemId: string, type: "customer" | "staff") {
      return type === "customer"
        ? props.itemsOwner[itemId]
        : t("module.tabs.move_item.staff");
    }

    function getOrderedByTooltipLabel(
      type: "customer" | "staff",
      staffName = "",
      customerPhone = ""
    ) {
      return type === "staff" ? staffName : phoneBeautifier(customerPhone);
    }

    return {
      t,
      tc,
      css,
      search,
      onClose,
      isLoading,
      filteredTabs,
      handleSearch,
      updateQuantity,
      onMoveItemToTab,
      itemQuantityUpdated,
      isMovingItemTabId,
      getOrderedByLabel,
      getOrderedByTooltipLabel,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
    };
  },
});
