


































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomModal,
  AtomMoney,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomAutoSizeTextarea,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
} from "@/v2/new-design-system";
import { OrgSpinButton } from "../components/org/spin-button";
import {
  OrgModifierGroup,
  IModifierGroupEmitChangePayload,
  IModifierGroupEmitValidatePayload,
} from "../components/org/modifier-group";
import {
  fetchMenuItem,
  IFetchMenuItemResponse,
} from "@/v2/repo/fetch-menu-item";
import { IPrice, ITabsItem, ITabsModifier } from "../types";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("tabs-item");

export default defineComponent({
  name: "TabsItem",
  components: {
    AtomLoading,
    AtomModal,
    AtomText,
    AtomMoney,
    AtomButton,
    AtomAutoSizeTextarea,
    OrgSpinButton,
    OrgModifierGroup,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    onAddItem: {
      type: Function as PropType<(item: ITabsItem) => void>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const item = ref<IFetchMenuItemResponse>();
    const modifierLists = computed(() => item.value?.modifierLists ?? []);
    const note = ref("");

    onMounted(() => {
      fetchItem();
    });

    async function fetchItem() {
      isLoading.value = true;

      try {
        const response = await fetchMenuItem({
          outletId: props.outletId,
          itemId: props.itemId,
        });

        if (response) {
          item.value = response;
        }
      } catch (error) {
        report(error);
      } finally {
        isLoading.value = false;
      }
    }

    function generateSubtitle(min: number, max: number, isRequired: boolean) {
      if (isRequired) {
        if (max === min && max > 1)
          return t("module.tabs.item.choose_at_least_x", { min });

        if (max > 1 && min > 1)
          return t("module.tabs.item.choose_at_least_x_up_to_x", {
            max,
            min,
          });

        if (max === 1) return t("module.tabs.item.choose_1");
      }

      if (max > 0) {
        return t("module.tabs.item.choose_up_to_x", { max });
      }

      return "";
    }

    const selectedModifiers = ref<Array<IModifierGroupEmitChangePayload>>([]);
    function onModifierListUpdated(
      modifierList: IModifierGroupEmitChangePayload
    ) {
      selectedModifiers.value = selectedModifiers.value.filter(
        (selected) => selected.value !== modifierList.value
      );
      selectedModifiers.value.push(modifierList);
    }

    const invalidLists = ref<Array<string>>([]);
    function handleValidate(payload: IModifierGroupEmitValidatePayload) {
      invalidLists.value = invalidLists.value.filter(
        (id) => id !== payload.value
      );
      if (!payload.valid) {
        invalidLists.value.push(payload.value);
      }
    }

    const totalUnitPrice = computed<IPrice>(() => {
      const itemPrice = item.value?.price.value ?? 0;
      const itemCurrency = item.value?.price.currency ?? "AED";

      const modifiersTotalPrice = selectedModifiers.value.reduce(
        (total: number, selected): number => total + selected.totalPrice,
        0
      );

      return {
        value: (itemPrice + modifiersTotalPrice) * quantity.value,
        currency: itemCurrency,
      };
    });

    const quantity = ref(1);
    function onCountUpdated(count: number) {
      if (count === 0) return;
      quantity.value = count;
    }

    function scrollToModifierGroup(id: string) {
      const modifierGroup = document.getElementById(id);
      if (modifierGroup) {
        modifierGroup.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }

    function addItem() {
      if (Boolean(invalidLists.value.length) || !item.value) {
        const firstModifierGroup = invalidLists.value[0];
        if (firstModifierGroup) scrollToModifierGroup(firstModifierGroup);
        return;
      }

      const modifiers: Array<ITabsModifier> = selectedModifiers.value.flatMap(
        (items) => items.selected
      );

      const { id, name, price, photo } = item.value;

      props.onAddItem({
        id,
        name,
        price,
        photo,
        quantity: quantity.value,
        note: note.value,
        modifiers,
      });
    }

    return {
      t,
      css,
      note,
      item,
      addItem,
      quantity,
      isLoading,
      invalidLists,
      modifierLists,
      handleValidate,
      onCountUpdated,
      selectedModifiers,
      onModifierListUpdated,
      totalUnitPrice,
      generateSubtitle,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
