

















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomDate,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTooltip,
} from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("tabs-ordered-by");

export default defineComponent({
  name: "TabsOrderedBy",
  components: {
    AtomDate,
    AtomText,
    AtomTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    tooltipLabel: {
      type: String,
      required: true,
    },
    dateTime: {
      type: Date,
      required: true,
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
