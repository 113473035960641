import {
  IFetchTabRequest,
  IFetchTabBodyPayload,
  IFetchTabContract,
  IFetchTabResponse,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class FetchTabError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTab");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchTab(
  request: IFetchTabRequest
): Promise<IFetchTabResponse> {
  let response: AxiosResponse<IFetchTabContract>;

  try {
    const params: IFetchTabBodyPayload = {
      outlet_id: request.outletId,
    };

    response = await http.get(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}`,
      { params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTabError(e);
  }

  return {
    id: response.data.id,
    reference: response.data.reference,
    spot: {
      id: response.data.table.id,
      name: response.data.table.name,
    },
    state: response.data.state,
    createdAt: new Date(response.data.created_at),
    cover: response.data.pax,
    closedAt: response.data.closed_at
      ? new Date(response.data.closed_at)
      : undefined,
    closureReason: response.data.closure_reason ?? undefined,
    openedBy:
      response.data.started_by.type === "owner"
        ? {
            type: "customer",
            phoneNumber: response.data.owner.phone_number,
          }
        : { type: "staff", name: response.data.started_by.name },
    updatedAt: response.data.updated_at
      ? new Date(response.data.updated_at)
      : undefined,
    owner: {
      name: response.data.owner?.name,
      phoneNumber: response.data.owner?.phone_number,
    },
    joiners: response.data.joiners?.length
      ? response.data.joiners?.map((joiner) => ({
          name: joiner.name,
          phoneNumber: joiner.phone_number,
        }))
      : [],
    lineItems: !response.data.line_items?.length
      ? []
      : response.data.line_items.map((item) => ({
          id: item.id,
          itemId: item.item_id,
          name: item.name,
          quantity: item.quantity,
          unitPrice: item.unit_price,
          addedBy:
            item.added_by.type === "staff"
              ? {
                  type: "staff",
                  name: item.added_by.name,
                }
              : {
                  type: "customer",
                  phoneNumber: item.added_by.phoneNumber,
                },
          addedAt: new Date(item.added_at),
          note: item.note ?? undefined,
          state: item.state,
          modifierLists: item.modifier_lists?.length
            ? item.modifier_lists.map((modifierList) => ({
                name: modifierList.name,
                modifierItems: modifierList.modifier_items?.length
                  ? modifierList.modifier_items.map((modifier) => ({
                      itemId: modifier.item_id,
                      name: modifier.name,
                      quantity: modifier.quantity,
                      unitPrice: modifier.unit_price,
                    }))
                  : [],
              }))
            : [],
          totalPrice: item.total_price,
        })),
    discounts: response.data.discounts?.length
      ? response.data.discounts.map((discount) => ({
          id: discount.id,
          code: discount.code,
          amount: discount.amount,
        }))
      : [],
    payments: response.data.payments?.length
      ? response.data.payments.map((payment) => ({
          addedBy: {
            email: payment.added_by?.email ?? undefined,
            phoneNumber: payment.added_by?.phone_number ?? undefined,
            type: payment.added_by.type,
          },
          amount: payment.amount,
          method: payment.method.length ? payment.method : undefined,
          paidAt: new Date(payment.paid_at),
          state: payment.state,
          tipAmount: payment.tip_amount,
          type: payment.type,
        }))
      : [],
    charges: response.data.charges?.length
      ? response.data.charges.map((charge) => ({
          name: charge.name,
          amount: charge.amount,
        }))
      : [],
    outstandingBalance: response.data.outstanding_balance,
    subtotal: response.data.subtotal,
    total: response.data.total,
  };
}
