




import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { ITabDomainTabState } from "../domain/tab-state";
import { useTabsTabStateLabel } from "./use-tab-state-label";

const css = bemBuilder("tabs-tab-state-label");

export default defineComponent({
  name: "TabsTabStateLabel",
  props: {
    state: {
      type: String as PropType<ITabDomainTabState>,
      required: true,
    },
  },
  setup(props) {
    const { labelMapping } = useTabsTabStateLabel();
    const label = computed(() => labelMapping[props.state]);

    return {
      css,
      label,
    };
  },
});
