


























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
} from "@vue/composition-api";
import {
  AtomModal,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  OrgFormInput,
} from "@/v2/new-design-system";
import { startTab } from "@/v2/repo/tabs/start-tab";
import { report } from "@chatfood/bug-reporter";
import { OrgSpinButton } from "../components/org/spin-button";
import { useHelpers } from "@/v2/composable/use-helpers";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { formValidation } from "@chatfood/core-utils";
import { useRouter } from "@/router";
import { Toast } from "@/design-system";
import { t } from "@/i18n";

const css = bemBuilder("tabs-start-tab");

export default defineComponent({
  name: "TabsStartTab",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    OrgSpinButton,
    OrgFormInput,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    spotId: {
      type: String,
      required: true,
    },
    spotName: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const isStarting = ref(false);

    const router = useRouter();

    const formValues = reactive({
      guestName: "",
      guestPhoneNumber: "",
    });

    const errors = ref({});

    const quantityCovers = ref(1);
    function onCountCovers(count: number) {
      if (count === 0) return;
      quantityCovers.value = count;
    }

    const formConfig = {
      guestPhoneNumber: {
        phoneNumber: {
          message: t("module.tabs.start_tab.invalid_phone_number"),
        },
      },
    };

    const blockOpenTab = computed(() => {
      return !formValues.guestName || !formValues.guestPhoneNumber;
    });

    const businessCurrency = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId).currency.code;
    });

    watch(
      () => formValues,
      () => {
        if (formValues.guestPhoneNumber) {
          formValues.guestPhoneNumber = `+${formValues.guestPhoneNumber.replace(
            /\D+/g,
            ""
          )}`;
        }
      },
      { deep: true }
    );

    function isFormValid() {
      errors.value = formValidation(formConfig, formValues);

      return !Object.keys(errors.value).length;
    }

    async function onStartTab() {
      if (!isFormValid()) return;

      isStarting.value = true;

      try {
        const tabId = await startTab({
          businessId: props.businessId,
          outletId: props.outletId,
          spotId: props.spotId,
          cover: quantityCovers.value,
          currency: businessCurrency.value,
          guestName: formValues.guestName,
          guestPhoneNumber: formValues.guestPhoneNumber,
        });

        new Toast().create({
          type: "success",
          text: t("module.tabs.start_tab.success_desc"),
        });

        router.replace({
          name: "tabs.view",
          params: {
            businessId: props.businessId,
            outletId: props.outletId,
            tabId: tabId,
          },
        });
      } catch (e: any) {
        if (e?.errors?.errorCode === "another_tab_is_already_open_on_table") {
          onClose();
          new Toast().create({
            type: "error",
            text: t("module.tabs.start_tab.error_tab_already_open"),
          });
          return;
        }

        report(e);
        generalErrorToast();
      } finally {
        isStarting.value = false;
      }
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      onClose,
      onStartTab,
      isStarting,
      blockOpenTab,
      formValues,
      errors,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      onCountCovers,
      quantityCovers,
    };
  },
});
