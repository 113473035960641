
























import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { AtomText, AtomTextTypeEnum } from "@/v2/new-design-system";
import { MolIconButton } from "../../mol/icon-button";

const css = bemBuilder("org-spin-button");

type IMolIconButtonProps = {
  iconName: string;
  theme?: "primary" | "secondary" | "outline" | "plain";
  size?: "medium" | "large";
  type?: "rounded" | "square";
};

type IType = "animated" | "fixed" | "square";

export default defineComponent({
  name: "AtomCheckbox",
  components: {
    AtomText,
    MolIconButton,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    type: {
      type: String as PropType<IType>,
      default: "animated",
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change", "increase", "decrease", "cleared"],
  setup(props, { slots, emit }) {
    const typeSettings: Record<IType, Partial<IMolIconButtonProps>> = {
      animated: {
        theme: "primary",
        size: "large",
        type: "rounded",
      },
      fixed: {
        theme: "secondary",
        size: "large",
        type: "rounded",
      },
      square: {
        theme: "outline",
        size: "medium",
        type: "square",
      },
    };

    const isAnimated = computed(() => props.type === "animated");
    const textType = computed(() =>
      props.type === "square"
        ? AtomTextTypeEnum.BODY
        : AtomTextTypeEnum.SUBTITLE
    );
    const hasNoCount = computed(() => props.count === 0);
    const hasOneCount = computed(() => props.count === 1);
    const hasCounts = computed(() => props.count > 0);
    const hasContent = computed(() => Boolean(slots.default));

    const bindProps = computed(() => typeSettings[props.type]);

    const decreaseIcon = computed(() =>
      isAnimated.value && (hasNoCount.value || hasOneCount.value)
        ? "delete"
        : "minus"
    );

    function onDecrease() {
      if (hasNoCount.value) return;

      const decreasedValue = props.count - 1;

      emit("change", decreasedValue, props.value);
      emit("decrease", decreasedValue, props.value);

      if (decreasedValue === 0) {
        emit("cleared", props.value);
      }
    }

    function onIncrease() {
      const increasedValue = props.count + 1;

      emit("change", increasedValue, props.value);
      emit("increase", increasedValue, props.value);
    }

    return {
      css,
      textType,
      bindProps,
      hasCounts,
      hasContent,
      onDecrease,
      onIncrease,
      decreaseIcon,
    };
  },
});
