import { ISendTabReceiptRequest, ISendTabReceiptBodyParams } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class SendTabReceiptError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: SendTabReceipt");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function sendTabReceipt(
  request: ISendTabReceiptRequest
): Promise<boolean> {
  let response: AxiosResponse;

  const params: ISendTabReceiptBodyParams = {
    outlet_id: request.outletId,
    emails: request.emails,
  };

  try {
    response = await http.post(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/send-receipt`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new SendTabReceiptError(e);
  }

  return Boolean(response);
}
