import { http } from "../../http";
import {
  IFetchSpotsContract,
  IFetchSpotsRequest,
  IFetchSpotsResponse,
} from "./types";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";

export class FetchSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchSpots");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
export async function fetchSpots({
  outletId,
}: IFetchSpotsRequest): Promise<IFetchSpotsResponse> {
  let response: AxiosResponse<IFetchSpotsContract>;

  try {
    response = await http.get(`/pat/locations/${outletId}/tables`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchSpotError(error);
  }

  return response.data
    ? response.data.map((item) => ({
        id: item.table_id,
        name: item.table_name,
        integrated: Boolean(item.is_pos_integrated),
        shortUrl: item.short_url || undefined,
        qrCodeUrl: item.qr_code_url || undefined,
        groupingLabel: item.grouping_label || undefined,
        seats: item.seats || undefined,
        section: item.grouping_label || undefined,
      }))
    : [];
}
