
























































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomCard,
  AtomIcon,
  AtomMoney,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  MolBackNav,
  MolMultiselect,
} from "@/v2/new-design-system";
import {
  fetchMenusForOutlet,
  IFetchMenusForOutletResponse,
} from "@/v2/repo/fetch-menus-for-outlet";
import {
  fetchMenuByMenuSlug,
  IFetchMenuByMenuSlugResponseItem,
  IFetchMenuByMenuSlugResponseCategories,
} from "@/v2/repo/fetch-menu-by-menu-slug";
import { TabsItem } from "../item";
import { ITabsItem } from "../types";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("tabs-categories");

export default defineComponent({
  name: "TabsCategories",
  components: {
    AtomLoading,
    AtomCard,
    AtomIcon,
    AtomMoney,
    AtomText,
    MolBackNav,
    MolMultiselect,
    TabsItem,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    tabReference: {
      type: String,
      required: true,
    },
    itemsAdded: {
      type: Array as PropType<Array<ITabsItem>>,
      default: () => [],
    },
    onAddItem: {
      type: Function as PropType<(item: ITabsItem) => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const hasNoMenu = ref(false);
    const showItemModal = ref(false);
    const itemIdSelected = ref("");
    const menus = ref<Array<IFetchMenusForOutletResponse>>([]);
    const menuSelected = ref<IFetchMenusForOutletResponse>();
    const categories = ref<IFetchMenuByMenuSlugResponseCategories>([]);

    onMounted(() => {
      fetchMenus();
    });

    async function fetchMenus() {
      try {
        const response = await fetchMenusForOutlet({
          businessId: props.businessId,
          outletId: props.outletId,
        });

        if (response.length) {
          menus.value = response;
          menuSelected.value = menus.value[0];

          fetchMenu(menus.value[0].slug);
        } else {
          hasNoMenu.value = true;
        }
      } catch {
        hasNoMenu.value = true;
      }
    }

    async function fetchMenu(menuSlug: string) {
      isLoading.value = true;

      try {
        const response = await fetchMenuByMenuSlug({
          outletId: props.outletId,
          menuSlug,
        });

        if (response) {
          categories.value = response.foodCategories;

          menuSelected.value = {
            id: response.id,
            name: response.name,
            slug: response.slug,
          };
        }
      } catch (error) {
        report(error);
      } finally {
        isLoading.value = false;
      }
    }

    function imageItem(photo = "") {
      return photo || require("./assets/chatfood-icon.png");
    }

    function isItemAdded(itemId: string) {
      return props.itemsAdded.find((item) => item.id === itemId);
    }

    async function addItem(item: IFetchMenuByMenuSlugResponseItem) {
      if (!item.inStock) return;

      itemIdSelected.value = item.id;
      showItemModal.value = true;
    }

    function scrollToCategory(categoryId: string) {
      const category = document.getElementById(categoryId);
      if (category) {
        category.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }

    function setMenu(menu: IFetchMenusForOutletResponse) {
      fetchMenu(menu.slug);
    }

    function addItemFromModal(item: ITabsItem) {
      props.onAddItem(item);
      closeModalMenuItem();
    }

    function closeModalMenuItem() {
      showItemModal.value = false;
      itemIdSelected.value = "";
    }

    return {
      t,
      css,
      menus,
      setMenu,
      addItem,
      imageItem,
      hasNoMenu,
      isLoading,
      categories,
      isItemAdded,
      menuSelected,
      itemIdSelected,
      showItemModal,
      scrollToCategory,
      addItemFromModal,
      closeModalMenuItem,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
