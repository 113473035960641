









import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system";

const css = bemBuilder("mol-icon-button");

export default defineComponent({
  name: "MolIconButton",
  components: {
    AtomIcon,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    theme: {
      type: String as PropType<"primary" | "secondary" | "outline" | "plain">,
      default: "primary",
    },
    size: {
      type: String as PropType<"medium" | "large">,
      default: "large",
    },
    type: {
      type: String as PropType<"rounded" | "square">,
      default: "rounded",
    },
  },
  emits: ["click"],
  setup(props) {
    const buttonSize: Record<string, number> = {
      medium: 14,
      large: 21,
    };

    const iconSize = computed(() => buttonSize[props.size]);

    return {
      css,
      iconSize,
    };
  },
});
