












































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  OrgFormInput,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { formValidation } from "@chatfood/core-utils";
import { sendTabReceipt } from "@/v2/repo/tabs/send-tab-receipt";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("tabs-email-receipt");

export default defineComponent({
  name: "TabsEmailReceipt",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    OrgFormInput,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const isLoading = ref(false);

    const formValues = ref<Record<string, string>>({
      email: "",
    });

    const errors = ref({});
    const formConfig = {
      email: {
        required: {
          message: t("module.tabs.email_receipt.required_email"),
        },
        email: {
          message: t("module.tabs.email_receipt.invalid_email"),
        },
      },
    };

    const isFormInvalid = (): boolean => {
      errors.value = formValidation(formConfig, formValues.value);

      return Boolean(Object.keys(errors.value).length);
    };

    async function onSendReceipt() {
      if (isFormInvalid()) return;

      isLoading.value = true;

      try {
        await sendTabReceipt({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          emails: [formValues.value.email],
        });

        new Toast().create({
          type: "success",
          title: t("module.tabs.email_receipt.success_title"),
          text: t("module.tabs.email_receipt.success_desc", {
            email: formValues.value.email,
          }),
        });
      } catch (e: any) {
        report(e);
        generalErrorToast();
      } finally {
        isLoading.value = false;
        onClose();
      }
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      errors,
      onClose,
      formValues,
      onSendReceipt,
      isLoading,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
