


























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { AtomLoading } from "@/v2/new-design-system";
import { TabsCategories } from "../categories";
import { TabsOrderList } from "../order-list";
import { ITabsItem } from "../types";
import { fetchTab, IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("tabs-pos");

export default defineComponent({
  name: "TabsPOS",
  components: {
    AtomLoading,
    TabsCategories,
    TabsOrderList,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const itemsAdded = ref<Array<ITabsItem>>([]);

    const tab = ref<IFetchTabResponse>();

    onMounted(() => getTab());

    async function getTab() {
      isLoading.value = true;

      try {
        tab.value = await fetchTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
        });
      } catch (error) {
        report(error);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function addItem(item: ITabsItem) {
      itemsAdded.value = [...itemsAdded.value, item];
    }

    function deleteItem(itemIndex: number) {
      const currentItemsAdded = [...itemsAdded.value];
      currentItemsAdded.splice(itemIndex, 1);
      itemsAdded.value = currentItemsAdded;
    }

    return {
      css,
      tab,
      addItem,
      isLoading,
      itemsAdded,
      deleteItem,
    };
  },
});
