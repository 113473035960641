import {
  IVoidItemRequest,
  IVoidItemContract,
  IVoidItemResponse,
  IVoidItemBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class VoidItemError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: VoidItem");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function voidItem(
  request: IVoidItemRequest
): Promise<IVoidItemResponse> {
  let response: AxiosResponse<IVoidItemContract>;

  const params: IVoidItemBodyPayload = {
    outlet_id: request.outletId,
    pin: request.pin,
    reason_id: request.reasonId,
    quantity: request.quantity,
  };

  try {
    response = await http.put(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/line-items/${request.lineItemId}/void`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new VoidItemError(e);
  }

  return {
    outletId: response.data.outlet_id,
  };
}
