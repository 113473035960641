




































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomMoney,
} from "@/v2/new-design-system";
import { CurrencyCodeEnum } from "@/v2/enum";
import { TabsStartTab } from "../start-tab";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { useRouter } from "@/router";
import { t, tc } from "@/i18n";

const css = bemBuilder("tabs-spot");

dayjs.extend(isToday);

interface IPrice {
  value: number;
  currency: CurrencyCodeEnum;
}

export default defineComponent({
  name: "TabsSpot",
  components: {
    AtomText,
    AtomMoney,
    TabsStartTab,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    spotId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      default: undefined,
    },
    spotName: {
      type: String,
      required: true,
    },
    outstandingBalance: {
      type: Object as PropType<IPrice>,
      default: undefined,
    },
    total: {
      type: Object as PropType<IPrice>,
      default: undefined,
    },
    ownerName: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const showStartTabModal = ref(false);
    function handleStartTabModal(state: boolean) {
      showStartTabModal.value = state;
    }

    const router = useRouter();

    const isSettledTab = computed(() => {
      return props.outstandingBalance?.value === 0 && props.total?.value > 0;
    });

    const isOpenTab = computed(() => {
      return props.tabId && !isSettledTab.value;
    });

    function actionSpot() {
      if (props.tabId) {
        router.push({
          name: "tabs.view",
          params: {
            businessId: props.businessId,
            outletId: props.outletId,
            tabId: props.tabId,
          },
        });
        return;
      }

      handleStartTabModal(true);
    }

    function addItems() {
      router.push({
        name: "tabs.add-items",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
        },
      });
    }

    return {
      t,
      tc,
      css,
      addItems,
      actionSpot,
      isSettledTab,
      isOpenTab,
      showStartTabModal,
      handleStartTabModal,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
