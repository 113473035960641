


































































































































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { report } from "@chatfood/bug-reporter";
import {
  defineComponent,
  ref,
  onBeforeUnmount,
  onMounted,
  computed,
  watch,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomLoading,
  AtomDate,
  MolBackNav,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { TabsOrderedItems } from "../ordered-items";
import { TabsSummary } from "../summary";
import { TabsAddPayment } from "../add-payment";
import { TabsCloseTab } from "../close-tab";
import { TabsMoveItem } from "../move-item";
import { TabsEmailReceipt } from "../email-receipt";
import { ReservationsAssignSpot } from "@/v2/module/reservations/assign-spot";
import { fetchTab, IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { printReceipt } from "@/v2/repo/tabs/print-receipt";
import { recalculateTab } from "@/v2/repo/tabs/recalculate-tab";
import { useDebounceValue } from "@/composable";
import { moveTabToSpot } from "@/v2/repo/tabs/move-tab-to-spot";
import { coverUpdate } from "@/v2/repo/tabs/cover-update";
import { useRouter } from "@/router";
import { phoneBeautifier } from "@chatfood/core-utils";
import { OrgSpinButton } from "../components/org/spin-button";
import {
  ITabChannelEvent,
  listenFromTab,
  unlistenFromTab,
} from "@/v2/core/broadcasting";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t } from "@/i18n";

const css = bemBuilder("tabs-view");

export default defineComponent({
  name: "TabsView",
  components: {
    AtomIcon,
    AtomText,
    AtomButton,
    AtomLoading,
    AtomDate,
    MolBackNav,
    TabsOrderedItems,
    TabsSummary,
    TabsAddPayment,
    TabsCloseTab,
    TabsMoveItem,
    TabsEmailReceipt,
    ReservationsAssignSpot,
    OrgSpinButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);

    const tab = ref<IFetchTabResponse>();
    const isTabOpen = computed(() => tab.value?.state === "OPEN");

    const router = useRouter();

    async function getTab(useLoading = false) {
      if (useLoading) isLoading.value = true;

      try {
        tab.value = await fetchTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
        });

        quantityCovers.value = tab.value.cover;
      } catch (error) {
        report(error);
        redirectMainView();
        generalErrorToast(t("module.tabs.view.tab_not_found"));
      } finally {
        isLoading.value = false;
      }
    }

    function redirectMainView() {
      router.replace({
        name: "tabs.live",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });
    }

    const tabParticipants = computed(() => {
      if (!tab.value) {
        return [];
      }

      return [tab.value.owner, ...tab.value.joiners];
    });

    function getParticipantName(phoneNumber: string) {
      return (
        tabParticipants.value.find((user) => user.phoneNumber === phoneNumber)
          ?.name ?? t("module.tabs.view.guest_label")
      );
    }

    const itemsOwner = computed(() => {
      if (!tab.value?.lineItems.length) {
        return {};
      }

      return tab.value.lineItems.reduce((prev, current) => {
        let name = "staff";

        if (current.addedBy.type === "customer") {
          const customer = tabParticipants.value.find(
            (user) =>
              current.addedBy.type === "customer" &&
              user.phoneNumber === current.addedBy.phoneNumber
          );
          name = customer?.name ?? "Customer";
        }

        prev[current.id] = name;

        return prev;
      }, {} as Record<string, string>);
    });

    async function onCoverUpdated() {
      if (!tab.value || quantityCovers.value === tab.value?.cover) return;

      try {
        await coverUpdate({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          pax: quantityCovers.value,
        });

        tab.value.cover = quantityCovers.value;
      } catch (e) {
        report(e);
      }
    }

    const quantityCovers = ref(1);
    function onCountUpdated(count: number) {
      if (count === 0) return;
      quantityCovers.value = count;
    }

    const { debouncedValue } = useDebounceValue(quantityCovers, 1000);
    watch(debouncedValue, onCoverUpdated);

    const showEmailReceiptModal = ref(false);
    function toggleEmailReceiptModal(state: boolean) {
      showEmailReceiptModal.value = state;
    }

    const showMoveTabModal = ref(false);
    function toggleMoveTabModal(state: boolean) {
      showMoveTabModal.value = state;
    }

    const showMoveItemModal = ref(false);
    function toggleMoveItemModal(state: boolean) {
      showMoveItemModal.value = state;
    }

    const showAddPaymentModal = ref(false);
    function toggleAddPaymentModal(state: boolean) {
      showAddPaymentModal.value = state;
    }

    const showCloseTabModal = ref(false);
    function toggleCloseTabModal(state: boolean) {
      showCloseTabModal.value = state;
    }

    const isRecalculatingTab = ref(false);
    async function onRecalculateTab() {
      isRecalculatingTab.value = true;

      try {
        await recalculateTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
        });
      } catch (e) {
        isRecalculatingTab.value = false;
        report(e);
        generalErrorToast();
      }
    }

    const isPrintingReceipt = ref(false);
    async function sendReceiptToPrinter() {
      isPrintingReceipt.value = true;

      try {
        await printReceipt({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
        });

        new Toast().create({
          type: "success",
          text: t("module.tabs.view.print_receipt_success_message"),
        });
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        isPrintingReceipt.value = false;
      }
    }

    async function whenTabRecalculated() {
      try {
        await getTab();
      } finally {
        isRecalculatingTab.value = false;
      }
    }

    async function onMovingTab(spotId: string) {
      try {
        await moveTabToSpot({
          businessId: props.businessId,
          outletId: props.outletId,
          spotId: spotId,
          tabId: props.tabId,
        });

        new Toast().create({
          type: "success",
          text: t("module.tabs.view.toast_success_desc_moved"),
        });
      } catch (e: any) {
        report(e);
        generalErrorToast();
      }
    }

    const whenListenerCalled = () => getTab(false);

    const regularEvents: ITabChannelEvent[] = [
      "tabs.started",
      "tabs.payment-failed",
      "tabs.payment-added",
      "tabs.payment-confirmed",
      "tabs.discount-applied",
      "tabs.discount-removed",
      "tabs.discount-adjusted",
      "tabs.moved-to-table",
      "tabs.settled",
      "tabs.line-item-voided",
      "tabs.item-added",
      "tabs.closed",
      "tabs.line-item-moved-in",
      "tabs.line-item-moved-out",
    ];

    onMounted(() => {
      getTab(true);
      listenFromTab(
        { tabId: props.tabId },
        "TAB_VIEW",
        regularEvents,
        whenListenerCalled
      );

      listenFromTab(
        { tabId: props.tabId },
        "TAB_VIEW",
        ["tabs.recalculated"],
        whenTabRecalculated
      );
    });

    onBeforeUnmount(() => {
      unlistenFromTab(
        { tabId: props.tabId },
        "TAB_VIEW",
        regularEvents,
        whenListenerCalled
      );

      unlistenFromTab(
        { tabId: props.tabId },
        "TAB_VIEW",
        ["tabs.recalculated"],
        whenTabRecalculated
      );
    });

    const itemToMove = ref<IFetchTabResponse["lineItems"][0]>();
    function selectItemToMove(item: IFetchTabResponse["lineItems"][0]) {
      itemToMove.value = item;
      toggleMoveItemModal(true);
    }

    return {
      t,
      css,
      tab,
      isLoading,
      isTabOpen,
      onMovingTab,
      onRecalculateTab,
      isRecalculatingTab,
      itemsOwner,
      showCloseTabModal,
      toggleCloseTabModal,
      showEmailReceiptModal,
      toggleEmailReceiptModal,
      showMoveTabModal,
      toggleMoveTabModal,
      showAddPaymentModal,
      toggleAddPaymentModal,
      sendReceiptToPrinter,
      isPrintingReceipt,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      quantityCovers,
      phoneBeautifier,
      getParticipantName,
      onCountUpdated,
      showMoveItemModal,
      toggleMoveItemModal,
      itemToMove,
      selectItemToMove,
    };
  },
});
