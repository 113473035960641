import {
  IAddOfflinePaymentRequest,
  IAddOfflinePaymentBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class AddOfflinePaymentError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AddOfflinePayment");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function addOfflinePayment(
  request: IAddOfflinePaymentRequest
): Promise<boolean> {
  let response: AxiosResponse;

  const params: IAddOfflinePaymentBodyPayload = {
    outlet_id: request.outletId,
    payment_method: request.paymentMethod,
    amount: request.amount,
    tip_amount: request.tipAmount,
  };

  try {
    response = await http.post(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/payments`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new AddOfflinePaymentError(e);
  }

  return Boolean(response);
}
