




























































































import {
  defineComponent,
  ref,
  PropType,
  computed,
  onMounted,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  MolFormControl,
  MolInputPassword,
  AtomRadioButton,
} from "@/v2/new-design-system";
import { t, tc } from "@/i18n";
import { OrgSpinButton } from "../components/org/spin-button";
import { report } from "@chatfood/bug-reporter";
import { voidItem } from "@/v2/repo/tabs/void-item";
import { Toast } from "@/design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { Currency } from "dinero.js";
import { IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { voidReasonRepo } from "@/module/void-reason";

const css = bemBuilder("tabs-ordered-items");

type IPrice = {
  value: number;
  currency: Currency;
};

export default defineComponent({
  name: "TabsVoidItems",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    MolFormControl,
    OrgSpinButton,
    MolInputPassword,
    AtomRadioButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    lineItemId: {
      type: String,
      required: true,
    },
    itemQuantity: {
      type: Number,
      required: true,
    },
    outstandingBalance: {
      type: Object as PropType<IFetchTabResponse["outstandingBalance"]>,
      required: true,
    },
    itemPrice: {
      type: Object as PropType<IPrice>,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const isVoiding = ref(false);
    const managerPin = ref("");
    const managerPinError = ref("");
    const hasOutstandingBalanceError = ref(false);
    const categories = ref<
      Awaited<ReturnType<typeof voidReasonRepo.getCategories>>
    >([]);

    const selectedReasonId = ref("");
    const categoryIdSelected = ref("");
    const reasons = computed(() => {
      return (
        categories.value.find(
          (category) => category.id === categoryIdSelected.value
        )?.reasons ?? []
      );
    });

    const counterQuantity = ref(props.itemQuantity);
    function onCountUpdated(count: number) {
      if (count === 0 || count > props.itemQuantity) return;
      counterQuantity.value = count;
    }

    const willBeOverPaid = computed(() => {
      return (
        counterQuantity.value * props.itemPrice.value >=
        props.outstandingBalance.value
      );
    });

    const requirementToVoid = computed(() => {
      return !managerPin.value.length || !selectedReasonId.value;
    });

    const categoryErrorMessage = computed(() => {
      return reasons.value.length > 0
        ? ""
        : formValidationErrors.value.reason_id?.[0];
    });

    const outstandingErrorMessage = computed(() => {
      return willBeOverPaid.value || hasOutstandingBalanceError.value
        ? t("module.tabs.void_items.outstanding_alert")
        : undefined;
    });

    async function fetchCategories() {
      try {
        categories.value = await voidReasonRepo.getCategories({
          businessId: props.businessId,
          filterOnlyEnabledReasons: true,
        });
      } catch (e) {
        report(e);
        generalErrorToast();
      }
    }

    function closeVoidModal() {
      emit("close");
    }

    const formValidationErrors = ref<Record<string, string[]>>({});
    async function onVoidItem() {
      formValidationErrors.value = {};
      isVoiding.value = true;
      managerPinError.value = "";
      hasOutstandingBalanceError.value = false;
      try {
        await voidItem({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: props.tabId,
          lineItemId: props.lineItemId,
          pin: managerPin.value,
          reasonId: selectedReasonId.value,
          quantity: counterQuantity.value,
        });
        new Toast().create({
          type: "success",
          text: t("module.tabs.ordered_items.success_void_item"),
        });
        closeVoidModal();
      } catch (e: any) {
        if (e?.errors?.errorCode === "invalid_pin") {
          managerPinError.value = t(
            "module.tabs.ordered_items.error_void_item_pin"
          );
          return;
        }

        if (
          e?.errors?.errorCode ===
          "unable_to_void_with_negative_outstanding_balance"
        ) {
          hasOutstandingBalanceError.value = true;
          return;
        }

        const hasFormValidationError = Boolean(
          e?.errors?.response?.data?.errors
        );
        if (hasFormValidationError) {
          formValidationErrors.value = e.errors.response.data.errors;
          return;
        }

        report(e);
        generalErrorToast();
      } finally {
        isVoiding.value = false;
      }
    }

    onMounted(() => {
      fetchCategories();
    });

    return {
      t,
      tc,
      css,
      onCountUpdated,
      onVoidItem,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      managerPin,
      managerPinError,
      isVoiding,
      closeVoidModal,
      counterQuantity,
      willBeOverPaid,
      outstandingErrorMessage,
      categories,
      selectedReasonId,
      requirementToVoid,
      AtomTextColorEnum,
      hasOutstandingBalanceError,
      reasons,
      categoryIdSelected,
      formValidationErrors,
      categoryErrorMessage,
    };
  },
});
