var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.css(), _vm.css('', _vm.type)]},[_c('MolCardHeader',{class:_vm.css('header'),attrs:{"subtitle":_vm.subtitle,"title":_vm.title,"is-required":_vm.isRequired,"has-error":!_vm.modifierState.valid}}),_c('div',{class:_vm.css('content')},_vm._l((_vm.modifierItems),function(groupItem){
var _obj;
return _c(_vm.componentSettings.component,_vm._g(_vm._b({key:groupItem.id,tag:"component",class:[
        _vm.css('item'),
        _vm.css('item', _vm.componentSettings.className),
        ( _obj = {}, _obj[_vm.css('item', 'disabled')] = groupItem.disabled, _obj ) ]},'component',Object.assign({}, _vm.componentSettings.defaultProps,
        {count: groupItem.quantity,
        value: groupItem.id,
        disabled: groupItem.disabled}),false),_vm.componentSettings.events),[_c('AtomText',{class:_vm.css('label')},[_vm._v(_vm._s(groupItem.name))]),(groupItem.price.value > 0)?_c('AtomText',{class:_vm.css('price'),attrs:{"type":_vm.AtomTextTypeEnum.SMALL}},[_vm._v(" + "),_c('AtomMoney',{attrs:{"amount":groupItem.price.value,"currency":groupItem.price.currency}})],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }