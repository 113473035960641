





















































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  watch,
  ref,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  AtomCheckbox,
  MolSearchBox,
  OrgCollapseGroup,
} from "@/v2/new-design-system";
import { OutletSwitcher } from "@/v2/components/outlet-switcher";
import { TabsSpot } from "../spot";
import {
  fetchSpots,
  IFetchSpotsResponse,
} from "@/v2/repo/spots-management/fetch-spots";
import {
  fetchTabsByOutlet,
  IFetchTabsByOutletResponse,
} from "@/v2/repo/tabs/fetch-tabs-by-outlet";
import { ITabsSpotGroups, ITabsSpot } from "../types";
import {
  ITabChannelEvent,
  listenFromTab,
  unlistenFromTab,
} from "@/v2/core/broadcasting";
import { removeSpecialCharacters } from "@chatfood/core-utils";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";
import { fetchTab } from "@/v2/repo/tabs/fetch-tab";

const css = bemBuilder("tabs-live");

export default defineComponent({
  name: "TabsLive",
  components: {
    AtomText,
    AtomLoading,
    MolSearchBox,
    OrgCollapseGroup,
    TabsSpot,
    OutletSwitcher,
    AtomCheckbox,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const isEmptyTablesVisible = ref(true);
    const isSettledTabsVisible = ref(true);
    const isOpenTabsVisible = ref(true);

    const searchQuery = ref("");

    const spotGroups = computed(() => {
      const groupedSpots = spots.value.reduce((groups, currentSpot) => {
        let group = groups.find(
          (obj) => obj.name === currentSpot.groupingLabel
        );

        if (!group) {
          group = {
            name: currentSpot.groupingLabel,
            spots: [],
          };

          groups.push(group);
        }

        let spot: ITabsSpot = {
          id: currentSpot.id,
          name: currentSpot.name,
        };

        const openTabInTheSpot = openTabs.value.find(
          (tab) => tab.spot.id === currentSpot.id
        );

        if (openTabInTheSpot) {
          spot.tabId = openTabInTheSpot.id;
          spot.ownerName = openTabInTheSpot.owner.name;
          spot.outstandingBalance = openTabInTheSpot.outstandingBalance;
          spot.total = openTabInTheSpot.total;
        }

        group.spots.push(spot);

        return groups;
      }, [] as Array<ITabsSpotGroups>);

      return groupedSpots.filter((group) => group.spots.length > 0);
    });

    const groupsCount = computed(() => spotGroups.value.length);

    const filteredSpots = computed(() => {
      let response = spotGroups.value;

      if (searchQuery.value) {
        const queryLowerCase = sanitizedString(searchQuery.value);

        response = response.map((group) => {
          return {
            name: group.name,
            spots: group.spots.filter((spot) => {
              const tableNameLowerCase = sanitizedString(spot.name);
              return tableNameLowerCase.includes(queryLowerCase);
            }),
          };
        });
      }

      if (!isEmptyTablesVisible.value) {
        response = response.map((group) => {
          return {
            name: group.name,
            spots: group.spots.filter((spot) => spot.tabId),
          };
        });
      }

      if (!isSettledTabsVisible.value) {
        response = response.map((group) => {
          return {
            name: group.name,
            spots: group.spots.filter(
              (spot) =>
                !(
                  spot.outstandingBalance &&
                  spot.total &&
                  spot.total.value > 0 &&
                  spot.outstandingBalance.value === 0
                )
            ),
          };
        });
      }

      if (!isOpenTabsVisible.value) {
        response = response.map((group) => {
          return {
            name: group.name,
            spots: group.spots.filter(
              (spot) =>
                !(
                  (spot.total && spot.total.value == 0) ||
                  (spot.outstandingBalance && spot.outstandingBalance.value > 0)
                )
            ),
          };
        });
      }

      return response.filter((group) => group.spots.length);
    });

    function sanitizedString(value: string | undefined) {
      const lowerString = value ? value.toLowerCase() : "";
      return removeSpecialCharacters(lowerString);
    }

    const wsEvents: ITabChannelEvent[] = [
      "tabs.closed",
      "tabs.moved-to-table",
      "tabs.started",
    ];

    watch(
      () => props.outletId,
      () => {
        getSpots();
        listenFromTab(
          { outletId: props.outletId },
          "TAB_LIST",
          wsEvents,
          handleTabs
        );

        listenFromTab(
          { outletId: props.outletId },
          "TAB_LIST",
          ["tabs.settled"],
          handleTabSettled
        );

        listenFromTab(
          { outletId: props.outletId },
          "TAB_LIST",
          ["tabs.item-added"],
          handleItemAdded
        );
      },
      { immediate: true }
    );

    const spots = ref<IFetchSpotsResponse>([]);

    async function getSpots() {
      isLoading.value = true;
      try {
        const response = await fetchSpots({ outletId: props.outletId });

        spots.value = response.sort((a, b) => {
          const lastAlphabetWord = "zzzz";

          return (
            (a.groupingLabel ?? lastAlphabetWord).localeCompare(
              b.groupingLabel ?? lastAlphabetWord,
              "en",
              {
                numeric: true,
              }
            ) ||
            a.name.localeCompare(b.name, "en", {
              numeric: true,
            })
          );
        });

        await handleTabs();
      } catch (error) {
        report(error);
      } finally {
        isLoading.value = false;
      }
    }

    const openTabs = ref<IFetchTabsByOutletResponse["data"]>([]);

    async function getOpenTabs() {
      try {
        openTabs.value = (
          await fetchTabsByOutlet({
            businessId: props.businessId,
            outletId: props.outletId,
            state: ["OPEN"],
            perPage: 999,
          })
        ).data;
      } catch (error) {
        report(error);
      }
    }

    async function handleTabs() {
      if (!spots.value) return;

      await getOpenTabs();
    }

    function setSearch(value: string) {
      searchQuery.value = value;
    }

    function handleTabSettled({ id }: { id: string }) {
      const tab = openTabs.value.find((t) => t.id === id);

      if (tab) {
        tab.outstandingBalance.value = 0;
      }
    }

    async function handleItemAdded({ id }: { id: string }) {
      const tab = openTabs.value.find((t) => t.id === id);

      if (!tab || tab.outstandingBalance.value > 0) return;

      try {
        const updatedTab = await fetchTab({
          businessId: props.businessId,
          outletId: props.outletId,
          tabId: id,
        });

        tab.outstandingBalance = updatedTab.outstandingBalance;
        tab.total = updatedTab.total;
      } catch (e) {
        report(e);
      }
    }

    onBeforeUnmount(() => {
      unlistenFromTab(
        { outletId: props.outletId },
        "TAB_LIST",
        wsEvents,
        handleTabs
      );

      unlistenFromTab(
        { outletId: props.outletId },
        "TAB_LIST",
        ["tabs.settled"],
        handleTabSettled
      );

      unlistenFromTab(
        { outletId: props.outletId },
        "TAB_LIST",
        ["tabs.item-added"],
        handleItemAdded
      );
    });

    return {
      t,
      css,
      setSearch,
      isLoading,
      spots,
      filteredSpots,
      groupsCount,
      isEmptyTablesVisible,
      isSettledTabsVisible,
      isOpenTabsVisible,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
