var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('header')},[_c('div',[_c('MolBackNav',{attrs:{"route":{
          name: 'tabs.view',
          params: { businessId: _vm.businessId, outletId: _vm.outletId, tabId: _vm.tabId },
        },"label":_vm.t('module.tabs.categories.back_label')}}),_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SUBTITLE}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.categories.heading", { reference: _vm.tabReference, }))+" ")])],1),(_vm.menus.length)?_c('MolMultiselect',{class:_vm.css('header-menu'),attrs:{"list":_vm.menus,"on-input":_vm.setMenu,"default-value":[_vm.menuSelected]}}):_vm._e()],1),_c('div',{class:[_vm.css('content'), ( _obj = {}, _obj[_vm.css('content', 'no-menu')] = _vm.hasNoMenu, _obj )]},[(_vm.hasNoMenu)?_c('div',{class:_vm.css('center')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SUBTITLE}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.categories.no_menu_title"))+" ")]),_c('AtomText',{attrs:{"color":_vm.AtomTextColorEnum.RAINY}},[_vm._v(" "+_vm._s(_vm.t("module.tabs.categories.no_menu_subtitle"))+" ")])],1):_c('div',[(_vm.isLoading)?_c('AtomLoading',{attrs:{"floating":""}}):[_c('div',{class:_vm.css('categories')},_vm._l((_vm.categories),function(category){return _c('a',{key:category.id,class:_vm.css('categories-item'),on:{"click":function($event){$event.preventDefault();return _vm.scrollToCategory(category.id)}}},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SMALL_BOLD}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)}),0),_c('div',{class:_vm.css('categories-list')},_vm._l((_vm.categories),function(category){return _c('div',{key:category.id},[_c('AtomText',{class:_vm.css('category-name'),attrs:{"id":category.id,"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('div',{class:_vm.css('items')},_vm._l((category.items),function(item){
        var _obj, _obj$1;
return _c('AtomCard',{key:item.id,class:[
                  _vm.css('item'),
                  ( _obj = {}, _obj[_vm.css('item', 'out-of-stock')] = !item.inStock, _obj ) ],nativeOn:{"click":function($event){return _vm.addItem(item)}}},[(_vm.isItemAdded(item.id))?_c('AtomIcon',{class:_vm.css('item-check'),attrs:{"name":"checkmark-circle"}}):_vm._e(),_c('div',{class:[
                    _vm.css('item-photo'),
                    ( _obj$1 = {}, _obj$1[_vm.css('item-photo', 'default')] = !item.photo, _obj$1 ) ],style:(("background-image: url(" + (_vm.imageItem(item.photo)) + ")"))}),_c('div',{class:_vm.css('item-desc')},[_c('AtomText',{class:_vm.css('item-desc-name'),attrs:{"type":_vm.AtomTextTypeEnum.SMALL_BOLD}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('AtomText',{attrs:{"color":_vm.AtomTextColorEnum.RAINY,"type":_vm.AtomTextTypeEnum.SMALL_BOLD}},[(item.inStock)?_c('AtomMoney',{attrs:{"amount":item.price.value,"currency":item.price.currency}}):_c('span',[_vm._v(" "+_vm._s(_vm.t("module.tabs.categories.out_of_stock"))+" ")])],1)],1)],1)}),1)],1)}),0)]],2)]),(_vm.showItemModal)?_c('TabsItem',{attrs:{"item-id":_vm.itemIdSelected,"outlet-id":_vm.outletId,"on-add-item":_vm.addItemFromModal,"on-close":_vm.closeModalMenuItem}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }