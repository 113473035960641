














































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, watch, ref, computed } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomMoney,
  AtomDate,
  MolPagination,
  OrgFilters,
  OrgTable,
  IOrgTableProps,
  IOrgFiltersConfigProps,
} from "@/v2/new-design-system";
import { useRouter } from "@/router";
import { OutletSwitcher } from "@/v2/components/outlet-switcher";
import {
  fetchTabsByOutlet,
  IFetchTabsByOutletRequest,
  IFetchTabsByOutletResponse,
} from "@/v2/repo/tabs/fetch-tabs-by-outlet";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";
import { TabsTabStateLabel } from "../tab-state-label";
import { useTabsTabStateLabel } from "../tab-state-label/use-tab-state-label";

const css = bemBuilder("tabs-history");

export default defineComponent({
  name: "TabsHistory",
  components: {
    AtomText,
    AtomMoney,
    AtomDate,
    MolPagination,
    OrgTable,
    OrgFilters,
    OutletSwitcher,
    TabsTabStateLabel,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const currentPage = ref(1);
    const pagination = ref<IFetchTabsByOutletResponse["meta"]>({
      currentPage: 1,
      lastPage: 1,
      totalRecords: 0,
      perPage: 10,
    });

    watch(
      () => props.outletId,
      () => {
        refreshTabs(1);
      },
      { immediate: true }
    );

    const tabs = ref<IFetchTabsByOutletResponse["data"]>([]);

    async function refreshTabs(page: number) {
      isLoading.value = true;
      try {
        const response = await fetchTabsByOutlet({
          businessId: props.businessId,
          outletId: props.outletId,
          state: filters.value.state
            ? // @ts-ignore the OrgFilters is not strong typed yet
              [filters.value.state.id]
            : undefined,
          perPage: 10,
          page,
          reference: filters.value.reference,
          createdAt: filters.value.createdAt,
          closedAt: filters.value.closedAt,
          sort: {
            field: currentSort.value.key,
            direction: currentSort.value.direction,
          },
        });

        currentPage.value = page;
        tabs.value = response.data;
        pagination.value = response.meta;
      } catch (error) {
        report(error);
      } finally {
        isLoading.value = false;
      }
    }

    const columns = computed<IOrgTableProps["columns"]>(() => ({
      reference: {
        header: () => t("module.tabs.history.th_reference"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      createdAt: {
        header: () => t("module.tabs.history.th_created_at"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      closedAt: {
        header: () => t("module.tabs.history.th_closed_at"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      owner: {
        header: () => t("module.tabs.history.th_owner"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      spot: {
        header: () => t("module.tabs.history.th_spot"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      total: {
        header: () => t("module.tabs.history.th_total"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      outstandingBalance: {
        header: () => t("module.tabs.history.th_outstanding"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      state: {
        header: () => t("module.tabs.history.th_state"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
    }));

    function handlePagination(page: number) {
      refreshTabs(page);
    }

    const router = useRouter();

    function onRowClick(tabId: string, event: MouseEvent) {
      const routeLocation = {
        name: "tabs.view",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
          tabId,
        },
      };

      if (event.metaKey || event.ctrlKey) {
        const route = router.resolve(routeLocation);
        window.open(route.href, "_blank");
        return;
      }

      router.push(routeLocation);
    }

    const { tabStateOptions: states } = useTabsTabStateLabel();

    const filters = ref({
      reference: "",
      state: undefined,
      createdAt: undefined,
      closedAt: undefined,
    });

    const filtersConfig = ref<IOrgFiltersConfigProps>({
      createdAt: {
        name: t("module.tabs.history.th_created_at"),
        type: "date-range",
        clearable: true,
      },
      closedAt: {
        name: t("module.tabs.history.th_closed_at"),
        type: "date-range",
        clearable: true,
      },
      reference: {
        name: t("module.tabs.history.th_reference"),
        type: "input-text",
        selected: filters.value.reference,
      },
      state: {
        name: t("module.tabs.history.th_state"),
        type: "single-select",
        clearable: true,
        list: states,
        selected: filters.value.state,
      },
    });

    function defineFilters(newFilters: any) {
      filters.value = {
        reference: newFilters.reference,
        state: newFilters.state,
        createdAt: newFilters.createdAt,
        closedAt: newFilters.closedAt,
      };
    }

    function onClearFilters(newFilters: any) {
      defineFilters(newFilters);
      refreshTabs(1);
    }

    function onApplyFilters(newFilters: any) {
      defineFilters(newFilters);
      refreshTabs(1);
    }

    type ISort = {
      key: NonNullable<IFetchTabsByOutletRequest["sort"]>["field"];
      direction: NonNullable<IFetchTabsByOutletRequest["sort"]>["direction"];
    };

    const currentSort = ref<ISort>({
      key: "reference",
      direction: "DESC",
    });

    const onTableSort = (
      key: ISort["key"],
      direction: ISort["direction"] | "NONE"
    ) => {
      const isSameCurrentSorting =
        currentSort.value.key === key &&
        currentSort.value.direction === direction;
      if (direction === "NONE" || isSameCurrentSorting) {
        return;
      }

      currentSort.value = { key, direction };
      refreshTabs(1);
    };

    return {
      t,
      css,
      onRowClick,
      isLoading,
      handlePagination,
      columns,
      pagination,
      tabs,
      onClearFilters,
      onApplyFilters,
      onTableSort,
      filtersConfig,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
