







































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  computed,
  watch,
  ref,
  Ref,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomAutoSizeTextarea,
  AtomButtonSizeEnum,
  AtomMoney,
} from "@/v2/new-design-system";
import { ITabsItem, ITabsModifier, IPrice } from "../types";
import { addItemsForTab } from "@/v2/repo/tabs/add-items-for-tab";
import { useHelpers } from "@/v2/composable/use-helpers";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { Toast } from "@/design-system";
import { useRouter } from "@/router";
import { t } from "@/i18n";

const css = bemBuilder("tabs-order-list");

export default defineComponent({
  name: "TabsOrderList",
  components: {
    AtomIcon,
    AtomAutoSizeTextarea,
    AtomText,
    AtomButton,
    AtomMoney,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    itemsAdded: {
      type: Array as PropType<Array<ITabsItem>>,
      default: () => [],
    },
    onDeleteItem: {
      type: Function as PropType<(itemIndex: number) => void>,
      required: true,
    },
    onClearAll: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const listItems = ref() as Ref<HTMLDivElement>;

    const businessCurrency = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId).currency.code;
    });

    const total = computed<IPrice>(() => {
      const totalCurrency =
        props.itemsAdded[0]?.price?.currency ?? businessCurrency.value;
      const totalValue = props.itemsAdded.reduce(
        (total: number, item: ITabsItem): number =>
          total +
          (item.price.value +
            item.modifiers.reduce(
              (total: number, modifier: ITabsModifier): number =>
                total + modifier.price.value * modifier.quantity,
              0
            )) *
            item.quantity,
        0
      );

      return {
        value: totalValue,
        currency: totalCurrency,
      };
    });

    const totalItems = computed(() =>
      props.itemsAdded.reduce(
        (total: number, item: ITabsItem): number => total + item.quantity,
        0
      )
    );

    function priceItem(item: ITabsItem) {
      return (
        item.modifiers.reduce(
          (total: number, modifier: ITabsModifier): number =>
            total + modifier.price.value * modifier.quantity,
          0
        ) + item.price.value
      );
    }

    watch(
      () => props.itemsAdded,
      async (newVal, oldVal) => {
        if (newVal.length < oldVal.length) return;

        setTimeout(() => {
          listItems.value.scrollTop = listItems.value.scrollHeight;
        }, 200);
      }
    );

    const router = useRouter();

    const isPlacing = ref(false);

    const notes = ref();

    function onInputNotes(input: string) {
      notes.value = input.trim();
    }

    async function placeOrder() {
      isPlacing.value = true;

      try {
        const request = {
          businessId: props.businessId,
          tabId: props.tabId,
          notes: notes.value,
          outletId: props.outletId,
          items: props.itemsAdded.map((item) => ({
            itemId: item.id,
            quantity: item.quantity,
            note: item.note,
            modifierItems: item.modifiers.map((modifier) => ({
              itemId: modifier.id,
              quantity: modifier.quantity,
              modifierListId: modifier.categoryId,
            })),
          })),
        };

        await addItemsForTab(request);

        new Toast().create({
          type: "success",
          text: t("module.tabs.order_list.toast_success_desc_place_order"),
        });

        router.replace({
          name: "tabs.view",
          params: {
            businessId: props.businessId,
            outletId: props.outletId,
            tabId: props.tabId,
          },
        });
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        isPlacing.value = false;
      }
    }

    return {
      t,
      css,
      total,
      priceItem,
      listItems,
      totalItems,
      isPlacing,
      placeOrder,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      onInputNotes,
      notes,
    };
  },
});
