import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";

interface IPrice {
  value: number;
  currency: string;
}

interface IFetchMenuByMenuSlugRequest {
  outletId: string;
  menuSlug: string;
}

export type IFetchMenuByMenuSlugContract = {
  id: string;
  name: string;
  slug: string;
  food_categories: Array<{
    id: string;
    name: string;
    photo: string | null;
    items: Array<{
      id: string;
      name: string;
      has_modifier: boolean;
      description: string;
      photo: string;
      price: IPrice;
      in_stock: boolean;
    }>;
  }>;
};

export type IFetchMenuByMenuSlugResponse = {
  id: string;
  name: string;
  slug: string;
  foodCategories: Array<{
    id: string;
    name: string;
    image?: string;
    items: Array<{
      id: string;
      name: string;
      hasModifier: boolean;
      description?: string;
      photo?: string;
      price: IPrice;
      inStock: boolean;
    }>;
  }>;
};

export type IFetchMenuByMenuSlugResponseCategories =
  IFetchMenuByMenuSlugResponse["foodCategories"];

export type IFetchMenuByMenuSlugResponseItem =
  IFetchMenuByMenuSlugResponse["foodCategories"][0]["items"][0];

export class FetchMenuByMenuSlugError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchMenuByMenuSlugError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchMenuByMenuSlug(
  request: IFetchMenuByMenuSlugRequest
): Promise<IFetchMenuByMenuSlugResponse> {
  let response: AxiosResponse<IFetchMenuByMenuSlugContract>;

  try {
    response = await http.get(
      `storefront/menus/${request.menuSlug}/for-outlet/${request.outletId}`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchMenuByMenuSlugError(error);
  }

  return {
    id: response.data.id,
    name: response.data.name,
    slug: response.data.slug,
    foodCategories: response.data.food_categories
      .filter((category) => category.items.length)
      .map((category) => ({
        id: category.id,
        name: category.name,
        image: category.photo ?? undefined,
        items: category.items.map((item) => ({
          id: item.id,
          name: item.name,
          hasModifier: item.has_modifier,
          description: item.description,
          photo: item.photo ?? undefined,
          price: item.price,
          inStock: item.in_stock,
        })),
      })),
  };
}
