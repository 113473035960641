import { t } from "@/i18n";
import { ITabDomainTabState } from "../domain/tab-state";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTabsTabStateLabel = () => {
  const labelMapping: Record<ITabDomainTabState, string> = {
    OPEN: t("module.tabs.tab_state_label.open"),
    CLOSED: t("module.tabs.tab_state_label.closed"),
  };

  // type assertion due to Object.keys always returning string[]
  // https://github.com/Microsoft/TypeScript/issues/12870
  const keysList = Object.keys(labelMapping) as Array<ITabDomainTabState>;

  const tabStateOptions: Array<{ id: ITabDomainTabState; name: string }> =
    keysList.map((key) => ({
      id: key,
      name: labelMapping[key],
    }));

  return {
    labelMapping,
    tabStateOptions,
  };
};
