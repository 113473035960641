import {
  IFetchEnabledPaymentMethodsByOrderingModeRequest,
  IFetchEnabledPaymentMethodsByOrderingModeContract,
  IFetchEnabledPaymentMethodsByOrderingModeResponse,
  IFetchEnabledPaymentMethodsByOrderingModeBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class FetchEnabledPaymentMethodsByOrderingModeError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchEnabledPaymentMethodsByOrderingMode");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/89242818b9b7e-get-enabled-payment-methods API Contract}
 */
export async function fetchEnabledPaymentMethodsByOrderingMode(
  request: IFetchEnabledPaymentMethodsByOrderingModeRequest
): Promise<IFetchEnabledPaymentMethodsByOrderingModeResponse> {
  const params: IFetchEnabledPaymentMethodsByOrderingModeBodyPayload = {
    ordering_service: request.orderingMode,
    outlet_id: request.outletId,
  };

  try {
    const { data } =
      await http.get<IFetchEnabledPaymentMethodsByOrderingModeContract>(
        `v1/businesses/${request.businessId}/payments/enabled-methods`,
        { params }
      );

    return data.data.sort((a, b) =>
      a.name.localeCompare(b.name, "en", {
        numeric: true,
      })
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchEnabledPaymentMethodsByOrderingModeError(e);
  }
}
