import { ICoverUpdateRequest, ICoverUpdateBodyParams } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class CoverUpdateError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CoverUpdate");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function coverUpdate(request: ICoverUpdateRequest): Promise<void> {
  const params: ICoverUpdateBodyParams = {
    outlet_id: request.outletId,
    pax: request.pax,
  };

  try {
    await http.put(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/pax`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CoverUpdateError(e);
  }
}
