
















































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomModal,
  AtomMoney,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomRadioButton,
  OrgFormMoneyInput,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import {
  IPaymentMethod,
  fetchEnabledPaymentMethodsByOrderingMode,
} from "@/v2/repo/outlet/fetch-enabled-payment-methods-by-ordering-mode";
// TODO: remove the FF and old add offline payments repository after release
import {
  addOfflinePayment as oldAddOfflinePayment,
  AddOfflinePaymentMethodEnum,
} from "@/v2/repo/tabs/add-offline-payment";
import {
  addOfflinePayment,
  IAddOfflinePaymentKnownErrorCode,
} from "@/v2/repo/tabs/add-offline-payment/v2";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { unWatchFlag, watchFlag } from "@/v2/core/feature-flag";
import { t } from "@/i18n";

const css = bemBuilder("tabs-add-payment");

export default defineComponent({
  name: "TabsAddPayment",
  components: {
    AtomModal,
    AtomMoney,
    AtomText,
    AtomButton,
    AtomRadioButton,
    OrgFormMoneyInput,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    outstandingBalance: {
      type: Object as PropType<IFetchTabResponse["outstandingBalance"]>,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const currency = ref(props.outstandingBalance.currency);
    const isModalReady = ref(false);
    const isAdding = ref(false);
    const tipAmount = ref(0);
    const payedSum = ref(0);

    const paymentMethods = ref<Array<IPaymentMethod>>([]);
    const selectedPaymentMethodId = ref();

    // TODO: remove after release the feature
    // Feature flag for the offline payments
    const canUseOfflinePayments = ref(false);
    function updateOfflinePaymentsFlag(val: boolean) {
      canUseOfflinePayments.value = val;
      getPaymentMethods();
      isModalReady.value = true;
    }

    onBeforeUnmount(() => {
      unWatchFlag("offline-payments", updateOfflinePaymentsFlag);
    });

    onMounted(() => {
      watchFlag("offline-payments", updateOfflinePaymentsFlag);
    });

    async function getPaymentMethods() {
      selectedPaymentMethodId.value = undefined;

      if (!canUseOfflinePayments.value) {
        paymentMethods.value = [
          {
            id: AddOfflinePaymentMethodEnum.CARD_OFFLINE,
            name: t("module.tabs.add_payment.card_label"),
          },
          {
            id: AddOfflinePaymentMethodEnum.CASH,
            name: t("module.tabs.add_payment.cash_label"),
          },
        ];
        return;
      }

      try {
        const res = await fetchEnabledPaymentMethodsByOrderingMode({
          businessId: props.businessId,
          outletId: props.outletId,
          orderingMode: "dine-in",
        });

        if (!res.length) {
          cancelAction(t("module.tabs.add_payment.error_no_payment_method"));
          return;
        }

        paymentMethods.value = res;
      } catch (error) {
        report(error);
        cancelAction();
      }
    }

    function cancelAction(message?: string) {
      onClose();
      generalErrorToast(message);
    }

    const total = computed(() => tipAmount.value + payedSum.value);

    function onSetPayedSum() {
      payedSum.value = props.outstandingBalance.value;
    }

    async function onAddPayment(shouldRetry = false) {
      if (isSubmitDisabled.value) return;

      isAdding.value = true;

      const findPaymentMethodSelected = paymentMethods.value.find(
        (payment) => payment.id === selectedPaymentMethodId.value
      );

      const payload = {
        businessId: props.businessId,
        outletId: props.outletId,
        tabId: props.tabId,
        amount: payedSum.value,
        tipAmount: tipAmount.value,
      };

      if (!findPaymentMethodSelected) {
        report(new Error("Tabs - Payment method not found"), {
          context: {
            payload,
          },
        });
        new Toast().create({
          type: "warning",
          text: t("module.tabs.add_payment.warning_payment_method_not_found"),
        });
        isAdding.value = false;
        return;
      }

      if (!canUseOfflinePayments.value) {
        try {
          await oldAddOfflinePayment({
            ...payload,
            paymentMethod:
              findPaymentMethodSelected.id as AddOfflinePaymentMethodEnum,
          });

          onSuccess();
        } catch (e: any) {
          report(e);
          cancelAction();
        }
        return;
      }

      try {
        await addOfflinePayment({
          ...payload,
          paymentMethod: findPaymentMethodSelected,
        });

        onSuccess();
      } catch (e: any) {
        const errorCode = e?.errors?.errorCode;

        if (errorCode) {
          handleError(errorCode, shouldRetry);
          return;
        }

        report(e);
        cancelAction();
      }
    }

    function onSuccess() {
      new Toast().create({
        type: "success",
        text: t("module.tabs.add_payment.toast_success_desc"),
      });

      isAdding.value = false;
      onClose();
    }

    function handleError(
      errorCode: IAddOfflinePaymentKnownErrorCode,
      isRetrying: boolean
    ) {
      const mapErrors: Record<IAddOfflinePaymentKnownErrorCode, string> = {
        no_outstanding_balance: t(
          "module.tabs.add_payment.error_no_outstanding_balance"
        ),
        insufficient_outstanding_balance: t(
          "module.tabs.add_payment.error_insufficient_outstanding_balance"
        ),
        tab_is_locked: t("module.tabs.add_payment.warning_retrying"),
      };

      const knownErrorMessage = mapErrors[errorCode];

      if (errorCode === "tab_is_locked") {
        if (isRetrying) {
          cancelAction();
        } else {
          new Toast().create({
            type: "warning",
            text: knownErrorMessage,
          });
          setTimeout(() => onAddPayment(true), 5000);
        }
      } else {
        cancelAction(knownErrorMessage);
      }
    }

    function onClose() {
      emit("close");
    }

    const isSubmitDisabled = computed(
      () =>
        payedSum.value <= 0 ||
        payedSum.value > props.outstandingBalance.value ||
        !selectedPaymentMethodId.value
    );

    return {
      t,
      css,
      onClose,
      onAddPayment,
      onSetPayedSum,
      isAdding,
      currency,
      payedSum,
      tipAmount,
      total,
      isModalReady,
      canUseOfflinePayments,
      selectedPaymentMethodId,
      paymentMethods,
      updateOfflinePaymentsFlag,
      isSubmitDisabled,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
