import { IPrintReceiptRequest, IPrintReceiptBodyPayload } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class PrintReceiptError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: PrintReceipt");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function printReceipt(
  request: IPrintReceiptRequest
): Promise<void> {
  const params: IPrintReceiptBodyPayload = {
    outlet_id: request.outletId,
  };

  try {
    await http.put(
      `api/v3/businesses/${request.businessId}/tabs/${request.tabId}/print-receipt`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new PrintReceiptError(e);
  }
}
